import {
  CrewClaimResolutionMethodEnum,
  crewClaimResolutionMethodEnumName,
  CrewClaimTypeEnum,
  toEnum,
} from 'corso-types';

import { Fragment, PropsWithChildren } from 'react';
import { useFieldArray } from 'react-hook-form';
import Alert from '~/components/Alert';
import Card from '~/components/Card';
import Disclosure, { SimpleSummary } from '~/components/Disclosure';
import LineItem from '~/components/LineItem';
import { Badge } from '~/components/ui/primitives/Badge';
import { useConfigSettings } from '~/hooks/useConfigSettings';
import {
  ReviewableResolutionMethod,
  reviewableResolutionMethods,
  ReviewMetaStatus,
  unsupportedResolutionMethods,
  useClaimReviewContext,
} from '~/providers/ClaimReviewProvider';
import { formatter } from '~/utils/formatter';
import ClaimLineItem from './ClaimLineItem';
import ClaimLineItemActions, {
  reviewMetaStatusSupportingText,
} from './ClaimLineItemActions';
import ClaimLineItemDetails from './ClaimLineItemDetail';
import ClaimLineItemNoteToCustomer from './ClaimLineItemNoteToCustomer';
import CustomerMedia from './CustomerMedia';
import Inspection from './Inspection';

function LineItemDisclosure({
  defaultOpen = false,
  title,
  supportingText,
  children,
}: PropsWithChildren<{
  defaultOpen?: boolean;
  title: string;
  supportingText: string;
}>) {
  return (
    <div className="rounded-md border">
      <Disclosure
        defaultOpen={defaultOpen}
        renderSummary={() => (
          <SimpleSummary>
            <span className="ml-2 text-sm text-corso-gray-500">{title}</span>
            <Badge className="ml-auto">{supportingText}</Badge>
          </SimpleSummary>
        )}
      >
        <div className="flex flex-col gap-2 p-3 lg:gap-4 lg:p-4">
          {children}
        </div>
      </Disclosure>
    </div>
  );
}

function shouldShowInspectionSection(
  claimType: 'Return' | 'Warranty',
  isWarrantyInspectionEnabled: boolean,
  isReturnInspectionEnabled: boolean,
) {
  if (claimType === 'Warranty' && isWarrantyInspectionEnabled) return true;

  if (claimType === 'Return' && isReturnInspectionEnabled) return true;

  return false;
}

function ReviewableClaimLineItemsList({
  resolutionMethod,
}: {
  resolutionMethod: ReviewableResolutionMethod;
}) {
  const { claimReview } = useClaimReviewContext();
  const claim = claimReview.watch('claim');

  const reviewLineItemsFields = useFieldArray({
    control: claimReview.control,
    name: `claim.reviewLineItems.${resolutionMethod}`,
  });
  const reviewLineItems = claim.reviewLineItems[resolutionMethod];
  const { claimType } = claim;

  const { data } = useConfigSettings(({ returns, warranties }) => ({
    returns,
    warranties,
  }));

  const { returns, warranties } = data ?? {};

  const showInspectionSection = shouldShowInspectionSection(
    claimType,
    !!warranties?.isWarrantyInspectionEnabled,
    !!returns?.isReturnInspectionEnabled,
  );

  return (
    <>
      {reviewLineItems.map((reviewLineItem, i) => {
        const { claimLineItem, reviewMetadata } = reviewLineItem;
        const alreadyResolved =
          reviewMetadata.status === ReviewMetaStatus.alreadyResolved;

        return (
          <Card key={reviewLineItem.claimLineItem.id}>
            <Card.Heading>
              {crewClaimResolutionMethodEnumName[resolutionMethod]} Request
            </Card.Heading>
            <ClaimLineItem claimLineItem={claimLineItem}>
              <div className="flex flex-col gap-2">
                <ClaimLineItemDetails claimLineItem={claimLineItem} />
                {claimLineItem.requestedResolutionMethodEnum ===
                  CrewClaimResolutionMethodEnum.variantExchange &&
                  claimLineItem.variantExchangeLineItem && (
                    <section>
                      <h6 className="mb-2 text-xs font-semibold text-corso-gray-500">
                        Exchange For
                      </h6>
                      <LineItem
                        variant="small"
                        sku={claimLineItem.variantExchangeLineItem.sku}
                        name={claimLineItem.variantExchangeLineItem.name}
                        imageUrl={claimLineItem.variantExchangeLineItem.imgUrl}
                        options={
                          claimLineItem.variantExchangeLineItem
                            .optionsFromPlatform
                        }
                        quantity={
                          claimLineItem.variantExchangeLineItem.quantity
                        }
                      />
                    </section>
                  )}
              </div>
            </ClaimLineItem>

            {!alreadyResolved && (
              <ClaimLineItemNoteToCustomer
                reviewLineItem={reviewLineItem}
                onChange={(updatedReviewLineItem) =>
                  reviewLineItemsFields.update(i, updatedReviewLineItem)
                }
              />
            )}

            {claimLineItem.images && claimLineItem.images.length > 0 && (
              <LineItemDisclosure
                title="Customer Media"
                defaultOpen
                supportingText={formatter.count(claimLineItem.images.length)}
              >
                <CustomerMedia assets={claimLineItem.images} />
              </LineItemDisclosure>
            )}
            {showInspectionSection && (
              <LineItemDisclosure
                title="Inspections"
                supportingText={formatter.count(
                  claimLineItem.inspections.length,
                )}
              >
                <Inspection claimLineItem={claimLineItem} />
              </LineItemDisclosure>
            )}

            {!alreadyResolved && (
              <LineItemDisclosure
                defaultOpen
                title="Resolution"
                supportingText={
                  reviewMetaStatusSupportingText[reviewMetadata.status]
                }
              >
                <ClaimLineItemActions
                  claimType={toEnum(claimType, CrewClaimTypeEnum)}
                  reviewLineItem={reviewLineItem}
                  onChange={
                    (updatedReviewLineItem) =>
                      reviewLineItemsFields.update(i, updatedReviewLineItem) // ! note potential concern as mentioned
                  }
                />
              </LineItemDisclosure>
            )}
          </Card>
        );
      })}
    </>
  );
}

export default function ClaimLineItemList() {
  const { claimReview } = useClaimReviewContext();
  const reviewLineItems = claimReview.getValues('claim.reviewLineItems');

  return (
    <>
      {reviewableResolutionMethods.map(
        (resolutionMethod) =>
          !!reviewLineItems[resolutionMethod].length && (
            <ReviewableClaimLineItemsList
              key={`claimResolutionLineItems-${resolutionMethod}`}
              resolutionMethod={resolutionMethod}
            />
          ),
      )}
      {unsupportedResolutionMethods.map(
        (resolutionMethod) =>
          !!reviewLineItems[resolutionMethod].length && (
            <Fragment key={`claimResolutionLineItems-${resolutionMethod}`}>
              {reviewLineItems[resolutionMethod].map((claimLineItem) => (
                <Card>
                  <Card.Heading>
                    {crewClaimResolutionMethodEnumName[resolutionMethod]}{' '}
                    Request
                  </Card.Heading>
                  <Alert
                    variant="warning"
                    title={`${crewClaimResolutionMethodEnumName[resolutionMethod]} Claims`}
                    message={`This claim line cannot be finalized, because it's resolution method is currently unsupported.`}
                  />
                  <ClaimLineItem
                    key={`claimLineItem-${claimLineItem.id}`}
                    claimLineItem={claimLineItem}
                  />
                </Card>
              ))}
            </Fragment>
          ),
      )}
    </>
  );
}
