import { useQuery } from '@tanstack/react-query';
import { CrewMerchantUi } from 'corso-types';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import api from '~/api';
import FullPageLoader from '~/components/FullPageLoader';
import FullPageStatus from '~/components/FullPageStatus';
import { Button } from '~/components/ui/primitives/Button';
import { LoadingContext } from '~/types';
import { identifyUser } from '~/utils/observability';
import { useAuthenticationContext } from './AuthenticationProvider';

type StoreUser =
  CrewMerchantUi.UserWithStoreUserRoles['storeUserRoles'][number];

type MerchantProviderContext = LoadingContext<{
  user: CrewMerchantUi.UserWithStoreUserRoles;
  storeUser: StoreUser;
  changeStoreUser: (storeId: StoreUser['storeId']) => void;
  userFullName: string;
}>;

const MerchantContext = createContext<MerchantProviderContext>({
  isLoading: true,
});

export function useMerchantContext() {
  const context = useContext(MerchantContext);
  // similar to throwing when not in a provider context
  if (context.isLoading) throw new Error('Merchant Context Still Loading');
  return context;
}

export function MerchantProvider({ children }: { children?: ReactNode }) {
  const [isLoading, setIsLoading] = useState(true); // independent of auth0 and user loading, so that it can be set only once both are loaded
  const { auth0User } = useAuthenticationContext();
  const [storeUser, setStoreUser] = useState<StoreUser>();

  const { storeId: storeIdPathParam } = useParams();
  const navigate = useNavigate();

  // TODO use loading and error states
  const {
    data: user,
    isError: isUserError,
    error,
    isPending: isUserPending,
  } = useQuery({
    enabled: !!auth0User.sub,
    queryKey: ['user', auth0User],
    queryFn: () => {
      if (!auth0User.sub) throw new Error('No Auth0 Subject'); // ? elevate to `useAuthenticationContext`
      return api.user.get(auth0User.sub);
    },
    retryDelay: 1000,
  });

  /** Can default to the first available by explicitly specifying `null` for the `storeId`. */
  const changeStoreUser = useCallback(
    (storeId: number | null) => {
      if (!user) return; // user not loaded yet

      // ! handle the case where a user has no storeUserRoles (logged out case... etc...)
      const [fallBackStoreUser] = user.storeUserRoles; // default to first store user
      if (!fallBackStoreUser) return; // no store user roles, will be handled by the relevant UI state

      const foundStoreUser =
        user.storeUserRoles.find((sur) => sur.storeId === storeId) ??
        fallBackStoreUser; // find store user in memory

      // found store user is active in memory and matches desired store
      if (foundStoreUser.storeId === storeUser?.storeId) {
        return;
      }

      setStoreUser(foundStoreUser); // set store user in memory
      identifyUser({
        email: user.email,
        storeId: foundStoreUser.storeId,
        storeName: foundStoreUser.store.name,
      });

      if (storeIdPathParam === `${foundStoreUser.storeId}`) return;
      navigate(`/${foundStoreUser.storeId}`); // navigate to home for the store
    },
    [user, storeUser?.storeId, storeIdPathParam, navigate],
  );

  useEffect(() => {
    changeStoreUser(storeIdPathParam ? Number(storeIdPathParam) : null); // attempt to change store user initially, and on path param changes
  }, [changeStoreUser, storeIdPathParam]);

  useEffect(() => {
    if (!user) return; // user not loaded yet
    if (!storeUser) return; // store user not loaded yet
    setIsLoading(false); // finally all loaded
  }, [user, storeUser]);

  const value = useMemo<MerchantProviderContext>(() => {
    if (isUserPending || isUserError || !storeUser) return { isLoading: true };

    return {
      isLoading,
      user,
      storeUser,
      changeStoreUser,
      get userFullName() {
        return `${user.firstName} ${user.lastName}`.trim();
      },
    };
  }, [isUserPending, isUserError, storeUser, isLoading, user, changeStoreUser]);

  // loading user/authenticated state
  if (isUserPending) return <FullPageLoader />;

  if (isUserError) {
    // the 404 case where the user is not found in the database
    console.error(error);
    return (
      <FullPageStatus
        title="Error Loading User"
        details={`Your user, ${auth0User.email}, could not be loaded or was not recognized.`}
      >
        <Button asChild variant="primary" size="lg">
          <NavLink to="/sign-out">Log Out</NavLink>
        </Button>
      </FullPageStatus>
    );
  }

  if (!user.storeUserRoles.length) {
    return (
      <FullPageStatus
        title="No Store Access"
        details={`Your user, ${user.email}, does not have access to any stores. You can be added by invitation from an admin.`}
      >
        <Button asChild variant="primary" size="lg">
          <NavLink to="/sign-out">Log Out</NavLink>
        </Button>
      </FullPageStatus>
    );
  }

  if (isLoading) return <FullPageLoader />;

  // we have merchant details...
  return (
    <MerchantContext.Provider value={value}>
      {children}
    </MerchantContext.Provider>
  );
}
