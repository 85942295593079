import {
  ClaimResolutionMethodEnum,
  ClaimStatusEnum,
  CrewMerchantUi,
  Pegasus,
  shippingClaimReasonName,
} from 'corso-types';

export { shippingClaimReasonName };

export const shippingClaimResolutionMethodName = {
  [ClaimResolutionMethodEnum.refund]: 'Refund',
  [ClaimResolutionMethodEnum.reorder]: 'Reorder',
} as const satisfies Record<ClaimResolutionMethodEnum, string>;

export const shippingClaimStatusName = {
  [ClaimStatusEnum.open]: 'Open',
  [ClaimStatusEnum.closedDenied]: 'Closed - Denied',
  [ClaimStatusEnum.closedRefunded]: 'Closed - Refunded',
  [ClaimStatusEnum.closedReordered]: 'Closed - Reordered',
  [ClaimStatusEnum.inProgress]: 'In Progress',
  [ClaimStatusEnum.closedResolved]: 'Closed - Resolved',
} as const satisfies Record<ClaimStatusEnum, string>;

type ClaimSource = NonNullable<CrewMerchantUi.ShippingClaim['source']>;
export const shippingClaimSourceName = {
  Customer_App: 'Customer Portal',
  Gladly_Hero_Integration: 'Gladly Hero Integration',
  Merchant_App: 'Merchant Admin App',
  Gorgias_Ticket_Widget: 'Gorgias Ticket Widget',
  Retool: 'Corso Concierge Team',
  Zowie_Inbox_Integration: 'Zowie Inbox Integration',
} as const satisfies Record<ClaimSource, string>;

export const estimatedDeliveryShippingCutOffOptions = [
  { value: 0, label: 'None' },
  { value: 10, label: '10:00AM' },
  { value: 10.15, label: '10:15AM' },
  { value: 10.3, label: '10:30AM' },
  { value: 10.45, label: '10:45AM' },
  { value: 11, label: '11:00AM' },
  { value: 11.15, label: '11:15AM' },
  { value: 11.3, label: '11:30AM' },
  { value: 11.45, label: '11:45AM' },
  { value: 12, label: '12:00PM' },
  { value: 12.15, label: '12:15PM' },
  { value: 12.3, label: '12:30PM' },
  { value: 12.45, label: '12:45PM' },
  { value: 13, label: '1:00PM' },
  { value: 13.15, label: '1:15PM' },
  { value: 13.3, label: '1:30PM' },
  { value: 13.45, label: '1:45PM' },
  { value: 14, label: '2:00PM' },
  { value: 14.15, label: '2:15PM' },
  { value: 14.3, label: '2:30PM' },
  { value: 14.45, label: '2:45PM' },
  { value: 15, label: '3:00PM' },
  { value: 15.15, label: '3:15PM' },
  { value: 15.3, label: '3:30PM' },
  { value: 15.45, label: '3:45PM' },
  { value: 16, label: '4:00PM' },
  { value: 16.15, label: '4:15PM' },
  { value: 16.3, label: '4:30PM' },
  { value: 16.45, label: '4:45PM' },
  { value: 17, label: '5:00PM' },
  { value: 17.15, label: '5:15PM' },
  { value: 17.3, label: '5:30PM' },
  { value: 17.45, label: '5:45PM' },
  { value: 18, label: '6:00PM' },
  { value: 18.15, label: '6:15PM' },
  { value: 18.3, label: '6:30PM' },
  { value: 18.45, label: '6:45PM' },
  { value: 19, label: '7:00PM' },
  { value: 19.15, label: '7:15PM' },
  { value: 19.3, label: '7:30PM' },
  { value: 19.45, label: '7:45PM' },
  { value: 20, label: '8:00PM' },
  { value: 20.15, label: '8:15PM' },
  { value: 20.3, label: '8:30PM' },
  { value: 20.45, label: '8:45PM' },
  { value: 21, label: '9:00PM' },
  { value: 21.15, label: '9:15PM' },
  { value: 21.3, label: '9:30PM' },
  { value: 21.45, label: '9:45PM' },
  { value: 22, label: '10:00PM' },
];

export const discountTypeText = {
  fixedAmount: 'Fixed Amount',
  percentage: 'Percentage',
  removePlusPricing: 'Remove Plus Pricing',
} as const satisfies Record<Pegasus.ShippingDiscountType, string>;

export const usHolidayOptionText = {
  NEW_YEARS_DAY: "New Year's Day",
  CHRISTMAS_DAY: 'Christmas',
  INDEPENDENCE_DAY: 'Independence Day',
  LABOR_DAY: 'Labor Day',
  MARTIN_LUTHER_KING_JR_DAY: 'Martin Luther King Jr. Day',
  MEMORIAL_DAY: 'Memorial Day',
  PRESIDENTS_DAY: "Presidents' Day",
  THANKSGIVING_DAY: 'Thanksgiving',
  VETERANS_DAY: 'Veterans Day',
  INDIGENOUS_PEOPLES_DAY: 'Indigenous Peoples Day',
  JUNETEENTH: 'Juneteenth',
} as const satisfies Record<Pegasus.USHoliday, string>;

export const usHolidayOptions = Object.values(Pegasus.USHoliday).map(
  (holiday) => ({
    label: usHolidayOptionText[holiday],
    value: holiday,
  }),
);
