import { faShopify } from '@fortawesome/free-brands-svg-icons';
import {
  faBadgePercent,
  faBox,
  faCog,
  faEye,
  faEyeSlash,
  faShieldAlt,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PlusIcon } from '@heroicons/react/20/solid';
import { PencilIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { CrewMerchantUi, isTruthy } from 'corso-types';

import { FormEventHandler, useEffect, useRef, useState } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import Alert from '~/components/Alert';
import Card from '~/components/Card';
import ConfirmModal from '~/components/ConfirmModal';
import EmptyStateAction from '~/components/EmptyStateAction';
import SwitchInput from '~/components/field/SwitchInput';
import FloatingSave from '~/components/FloatingSave';
import Icon from '~/components/Icon';
import Modal from '~/components/Modal';
import Page from '~/components/Page';
import Separator from '~/components/Separator';
import { Action } from '~/components/ui/Action';
import { MultiSelect, MultiSelectOption } from '~/components/ui/MultiSelect';
import { Badge } from '~/components/ui/primitives/Badge';
import { Button } from '~/components/ui/primitives/Button';
import SimpleSelect from '~/components/ui/SimpleSelect';
import {
  useConfigSettings,
  useConfigSettingsUpdate,
  useConfigureGsp,
} from '~/hooks/useConfigSettings';
import useIsCorsoAdmin from '~/hooks/useIsCorsoAdmin';
import useIsTest from '~/hooks/useIsTest';
import { useProtectionRates } from '~/hooks/useProtectionRates';
import {
  useHasCheckoutFunctionality,
  useShopifyPlan,
} from '~/hooks/useShopifyPlan';
import { useStoreRules } from '~/hooks/useStoreRules';
import ManageShippingRates from '~/pages/settings/ShippingRates/ManageShippingRates';
import ShippingRateForm from '~/pages/settings/ShippingRates/ShippingRateForm';
import { useMerchantContext } from '~/providers/MerchantProvider';
import {
  shipProtectSettingsFormSchema,
  ShipProtectSettingsFormValues,
} from '~/types';
import { cn } from '~/utils/shadcn';
import ShippingPlusRules from './ShippingPlusRules';
import { ShippingPlusWidgetPreview } from './ShippingPlusWidgetPreview';

const shipProtectSettingsFormId = 'shipping-protection-settings';

function BackupShippingRates({
  show,
  onClose: closeBackupRateSelector,
}: {
  show: boolean;
  onClose: () => void;
}) {
  const methods = useFormContext<ShipProtectSettingsFormValues>();
  const { data: protectionRates } = useProtectionRates();

  const protectionRateOptions =
    protectionRates?.map((rate) => ({
      value: `${rate.id}`,
      label: rate.name,
    })) ?? [];

  const findOption = (values?: number[]) =>
    values
      ?.map((value) =>
        protectionRateOptions.find((option) => option.value === `${value}`),
      )
      .filter(isTruthy) ?? [];

  const backupRatesWIthCarrierService = findOption(
    methods.watch(`widgetConfig.shippingPlus.backupRateIds`),
  )?.some(
    (option) =>
      protectionRates?.find((rate) => rate.id === Number(option.value))
        ?.carrierService,
  );

  return (
    <Modal
      show={show}
      onClose={closeBackupRateSelector}
      title="Backup Shipping Rates"
      actions={
        <>
          <Action onClick={closeBackupRateSelector}>Cancel</Action>
          <Action
            variant="primary"
            type="submit"
            disabled={!methods.formState.isDirty}
            form={shipProtectSettingsFormId}
            onClick={() => {
              closeBackupRateSelector();
            }}
          >
            Save
          </Action>
        </>
      }
    >
      {backupRatesWIthCarrierService && (
        <Alert
          message="Backup rates with an attached carrier service will not be applied!"
          variant="warning"
        />
      )}
      <Controller
        control={methods.control}
        name="widgetConfig.shippingPlus.backupRateIds"
        render={({ field: f, fieldState }) => (
          <MultiSelect
            clearable
            estimateSize={114}
            labelVisuallyHidden
            label="Backup Rates"
            options={protectionRateOptions}
            details="The rates to use as a backup if for any reason the Shipping Plus service is unavailable, or delayed. In the event of a failure, or misconfiguration these rates will be used to ensure checkout is still possible."
            placeholder="Select Rates"
            value={findOption(f.value) ?? []}
            onChange={(selected) =>
              f.onChange(selected.map(({ value }) => Number(value)))
            }
            error={fieldState.error?.message}
          />
        )}
      />
    </Modal>
  );
}

const rateOptionDefaultValues = {
  all: 'all',
  unassigned: 'unassigned',
} as const;

const getRateOptionsWithCount = ({
  rates,
  rules,
}: {
  rates: CrewMerchantUi.ProtectionRate[];
  rules: CrewMerchantUi.StoreRule[];
}) => {
  const allAssignedStoreShipRateIds = rules
    .map(({ rule }) => rule.event) // extract focused part
    .filter((event) => event.type === 'quoteOrderShipment')
    .flatMap((event) => event.params.storeShipRateIds ?? []); // only need the nullish coalescing if storeShipRateIds is nullish

  const unassignedRates = rates.filter(
    (rate) => !allAssignedStoreShipRateIds.includes(rate.id),
  );
  return [
    {
      value: rateOptionDefaultValues.all,
      label: `All Rates (${rates.length})`,
    },
    {
      value: rateOptionDefaultValues.unassigned,
      label: `Not Assigned to a Shipping Geo Zone Rule (${unassignedRates.length})`,
    },
    ...rules.map(({ rule, name, id }) => {
      const storeShipRateIds =
        'storeShipRateIds' in rule.event.params ?
          rule.event.params.storeShipRateIds
        : [];

      const rateCount = rates.filter((rate) =>
        storeShipRateIds.includes(rate.id),
      ).length;

      return {
        value: `${id}`,
        label: `${name} (${rateCount})`,
      };
    }),
  ];
};

function BackupRateAction({
  count,
  onClick,
}: {
  count: number;
  onClick: () => void;
}) {
  return (
    <Button
      type="button"
      onClick={onClick}
      variant="ghost"
      icon
      className="relative"
      aria-label="Open backup rates"
    >
      <Icon icon={faShieldAlt} />
      <span
        className={cn(
          'absolute right-0 top-0 flex h-5 w-5 -translate-y-1/4 translate-x-1/4 items-center justify-center rounded-full text-xs font-bold text-white',
          count === 0 ? 'bg-red-500' : 'bg-corso-blue-700',
        )}
        aria-label={`${count} backup rates available`}
      >
        {count}
      </span>
    </Button>
  );
}

function ShippingRates() {
  const { mutate: configureSp, isPending } = useConfigureGsp();

  const { data: protectionRates, refetch: refetchProtectionRates } =
    useProtectionRates();

  const [filteredRates, setFilteredRates] = useState<
    CrewMerchantUi.ProtectionRate[]
  >([]);

  const { data: storeRules } = useStoreRules();

  const quoteShipmentAutomationRules =
    storeRules
      ?.filter((sr) => sr.rule.event.type === 'quoteOrderShipment')
      .sort(
        (a, b) =>
          new Date(b.createdOn).valueOf() - new Date(a.createdOn).valueOf(),
      ) ?? [];

  useEffect(() => {
    setFilteredRates(protectionRates);
  }, [protectionRates]);

  const { data } = useConfigSettings(
    ({ shippingProtection }) => shippingProtection,
  );

  const [showBackupRateSelector, setShowBackupRateSelector] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [hideRates, setShowHideRates] = useState(false);

  const ratesExist = protectionRates && protectionRates.length > 0;

  const backupRatesExist =
    !!data?.widgetConfig?.shippingPlus?.backupRateIds?.length;

  const shippingRuleOptions = getRateOptionsWithCount({
    rates: protectionRates ?? [],
    rules: quoteShipmentAutomationRules,
  });

  const handleShippingRuleSelect = (value: string) => {
    setShowHideRates(false);
    if (value === rateOptionDefaultValues.all) {
      setFilteredRates(protectionRates ?? []);
      return;
    }

    if (value === rateOptionDefaultValues.unassigned) {
      const allAssignedStoreShipRateIds = quoteShipmentAutomationRules
        .map(({ rule }) => rule.event) // extract focused part
        .filter((event) => event.type === 'quoteOrderShipment')
        .flatMap((event) => event.params.storeShipRateIds ?? []); // only need the nullish coalescing if storeShipRateIds is nullish

      setFilteredRates(
        protectionRates?.filter(
          (rate) => !allAssignedStoreShipRateIds.includes(rate.id),
        ) ?? [],
      );
      return;
    }

    const sr = quoteShipmentAutomationRules.find((r) => r.id === Number(value));
    if (sr?.rule?.event?.type === 'quoteOrderShipment') {
      const { storeShipRateIds } = sr.rule.event.params;

      setFilteredRates(
        protectionRates?.filter((rate) => storeShipRateIds.includes(rate.id)) ??
          [],
      );
    }
  };

  const backupRateCount =
    data?.widgetConfig?.shippingPlus?.backupRateIds?.length ?? 0;
  return (
    <Card>
      <div className="flex flex-row items-center justify-between">
        <Card.Heading>Shipping Rates</Card.Heading>

        <div className="flex space-x-2">
          <Action
            icon={hideRates ? faEye : faEyeSlash}
            onClick={() => setShowHideRates(!hideRates)}
            accessibilityLabel="Collapse Rates"
            variant="ghost"
          />

          <Action
            icon={faShopify}
            loading={isPending}
            onClick={() => setShowConfirmationModal(true)}
            accessibilityLabel="Refresh Rates from Shopify"
            variant="ghost"
          />

          <BackupRateAction
            count={backupRateCount}
            onClick={() => setShowBackupRateSelector(true)}
          />

          <Action
            icon={PlusIcon}
            onClick={() => setShowForm(true)}
            accessibilityLabel="Add Rate"
            variant="ghost"
          />
        </div>

        <ConfirmModal
          prompt="Are you sure you'd like to sync your Shipping Rates and Shipping Zone Rules?"
          title="Sync Rates And Shipping Zones"
          show={showConfirmationModal}
          confirmText="Confirm"
          variant="primary"
          onConfirm={() => {
            configureSp('syncShippingZones');
            setShowConfirmationModal(false);
            refetchProtectionRates().catch(console.error);
          }}
          onCancel={() => setShowConfirmationModal(false)}
        />
        <BackupShippingRates
          show={showBackupRateSelector}
          onClose={() => setShowBackupRateSelector(false)}
        />
      </div>

      {!backupRatesExist && ratesExist && (
        <Alert
          message={
            <>
              No Backup Rates configured. To ensure checkout works in case of
              failure or misconfiguration, configure them{' '}
              <Action
                onClick={() => setShowBackupRateSelector(true)}
                variant="link"
                accessibilityLabel="Configure Backup Rates"
              >
                here
              </Action>
            </>
          }
          variant="warning"
        />
      )}

      <SimpleSelect
        label="Filter by Shipping Geo Zone Rule"
        onChange={(value) => handleShippingRuleSelect(value)}
        options={shippingRuleOptions}
        defaultValue="all"
      />

      <Separator />

      {/* eslint-disable-next-line no-nested-ternary */}
      {!ratesExist ?
        <EmptyStateAction.Button
          onClick={() => setShowForm(true)}
          icon={<FontAwesomeIcon icon={faBox} />}
          label="Create A Shipping Rate"
        />
      : filteredRates.length === 0 ?
        <Alert message="No shipping rates assigned." variant="DEFAULT" />
      : <ManageShippingRates
          rates={filteredRates}
          editable
          hidden={hideRates}
        />
      }

      <ShippingRateForm show={showForm} onClose={() => setShowForm(false)} />
    </Card>
  );
}

function AppUpgradeRequired() {
  return (
    <Page title="Shipping Plus">
      <Card>
        <Alert
          title="App Upgrade Required"
          message="In order to use Shipping Plus, you must upgrade your Corso app to the latest version. This can be done by selecting the Corso app in your Shopify admin and clicking the 'Update' button."
          variant="DEFAULT"
        />
      </Card>
    </Page>
  );
}

function AdvancedSettings({
  show,
  onClose,
}: {
  show: boolean;
  onClose: () => void;
}) {
  const methods = useFormContext<ShipProtectSettingsFormValues>();
  const { mutate: configureSp } = useConfigureGsp();

  const isApplicationDiscountConfigured = methods.watch(
    'isApplicationDiscountConfigured',
  );

  const [showDiscountModal, setShowDiscountModal] = useState(false);

  return (
    <Modal
      size="xl"
      show={show}
      onClose={onClose}
      title="Advanced Settings"
      actions={
        <>
          <Action onClick={onClose}>Cancel</Action>
          <Action
            variant="primary"
            type="submit"
            disabled={!methods.formState.isDirty}
            form={shipProtectSettingsFormId}
            onClick={() => {
              onClose();
            }}
          >
            Save
          </Action>
        </>
      }
    >
      <Alert
        message="The following settings are only visible to Corso Admin Users."
        variant="info"
      />

      <Card>
        <Card.Heading>Shipping Plus</Card.Heading>

        <Controller
          control={methods.control}
          name="widgetConfig.shippingPlus.isWidgetDebugEnabled"
          render={({ field: { onChange, value }, fieldState }) => (
            <SwitchInput
              id="debug-checkout-widget"
              label="Debug Mode"
              details="When enabled, additional debug information will be displayed in the console."
              checked={!!value}
              onChange={onChange}
              error={fieldState.error?.message}
            />
          )}
        />

        <Controller
          control={methods.control}
          name="widgetConfig.shippingPlus.isUpdateExistingDiscountsEnabled"
          render={({ field: { onChange, value }, fieldState }) => (
            <SwitchInput
              id="update-existing-discounts"
              label="Update New And Existing Discounts"
              details="When enabled, existing discounts will be updated to combine with Shipping discounts, and all newly created discounts will also be updated."
              onChange={onChange}
              checked={!!value}
              error={fieldState.error?.message}
            />
          )}
        />

        <Controller
          control={methods.control}
          name="widgetConfig.shippingPlus.isRateTableCacheEnabled"
          render={({ field: { onChange, value }, fieldState }) => (
            <SwitchInput
              id="rate-table-cache-configured"
              label="Use Rate Table Cache"
              details="When enabled, calculated shipping rates can be retrieved from a cached rate table. "
              onChange={onChange}
              checked={!!value}
              error={fieldState.error?.message}
            />
          )}
        />

        {isApplicationDiscountConfigured && (
          <div>
            <Action
              onClick={() => setShowDiscountModal(true)}
              variant="primary"
              icon={faBadgePercent}
            >
              Trigger Bulk Discount Sync
            </Action>
          </div>
        )}
      </Card>

      <Card>
        <div className="flex flex-row justify-between">
          <Card.Heading>Delivery Customization</Card.Heading>

          <Controller
            control={methods.control}
            name="widgetConfig.shippingPlus.deliveryCustomization.isEnabled"
            render={({ field: { onChange, value }, fieldState }) => (
              <SwitchInput
                id="enable-delivery-customization"
                label="Enable Delivery Customization"
                labelVisuallyHidden
                onChange={onChange}
                checked={!!value}
                error={fieldState.error?.message}
              />
            )}
          />
        </div>

        <Controller
          control={methods.control}
          name="widgetConfig.shippingPlus.deliveryCustomization.isReorderShippingLinesEnabled"
          render={({ field: { onChange, value }, fieldState }) => (
            <SwitchInput
              id="reorder-shipping-lines"
              label="Reorder Shipping Options"
              details="When enabled, the Plus rates will be reordered to appear at the top of the Shipping Options."
              onChange={onChange}
              checked={!!value}
              error={fieldState.error?.message}
            />
          )}
        />

        <Controller
          control={methods.control}
          name="widgetConfig.shippingPlus.deliveryCustomization.hideRateNames"
          render={({ field: f, fieldState }) => (
            <MultiSelect
              creatable
              label="Hide Shipping Rates"
              options={[]}
              details="The rate titles to hide at checkout, these rates will not be displayed to the customer."
              placeholder="Add Rate"
              value={
                f.value?.map((v) => ({
                  value: v.toString(),
                  label: v,
                })) ?? []
              }
              onChange={(o) => f.onChange(o.map((v) => v.value))}
              error={fieldState.error?.message}
            />
          )}
        />
      </Card>

      <ConfirmModal
        prompt="Are you sure you'd like trigger a bulk discount sync? This may take a few minutes to complete. Please do not trigger multiple syncs at once."
        title="Bulk Discount Sync"
        show={showDiscountModal}
        confirmText="Confirm"
        variant="primary"
        onConfirm={() => {
          configureSp('bulkUpdateDiscounts');
          setShowDiscountModal(false);
          onClose();
        }}
        onCancel={() => {
          setShowDiscountModal(false);
          onClose();
        }}
      />
    </Modal>
  );
}

function MerchantPaidShippingLines() {
  const { data } = useConfigSettings(
    ({ shippingProtection }) => shippingProtection,
  );

  const merchantPaidShippingLines =
    data?.shipProtectUnits?.filter(
      (unit) => unit.implementationType === 'Shipping_Line',
    ) ?? [];

  if (!merchantPaidShippingLines.length) return null;

  return (
    <Card>
      <Card.Heading>Merchant Paid Shipping Lines</Card.Heading>
      <Alert
        message="Merchant Paid Shipping Lines are enabled. Any orders that contain these shipping titles will be considered merchant paid, regardless of whether Shipping Plus is enabled."
        variant="info"
      />

      {merchantPaidShippingLines.map((unit) => (
        <div key={unit.idFromPlatform} className="flex gap-2">
          <div>
            <Badge>{unit.idFromPlatform}</Badge>
          </div>
        </div>
      ))}
    </Card>
  );
}

export default function ShippingPlusSettings() {
  const formRef = useRef<HTMLFormElement>(null);

  const { data } = useConfigSettings(
    ({ shippingProtection }) => shippingProtection,
  );

  const methods = useForm<ShipProtectSettingsFormValues>({
    resolver: zodResolver(shipProtectSettingsFormSchema),
    values: data,
  });

  const { mutateAsync: saveChanges } = useConfigSettingsUpdate();

  const submitHandler: FormEventHandler = (event) => {
    methods
      .handleSubmit(
        (values) => saveChanges({ shippingProtection: values }),
        (errors) => {
          console.error(errors);
        },
      )(event)
      .catch(console.error)
      .then(() => {
        methods.reset();
      })
      .catch(console.error);
  };

  const {
    storeUser: {
      store: { id: storeId },
    },
  } = useMerchantContext();

  const { isShippingLineProtectionOffered, accessScopes } = data ?? {};

  // TODO potentially use the enum values here
  const hasDeliveryCustomizationScope =
    accessScopes?.includes('write_delivery_customizations') ?? false;
  const hasWriteShippingScope =
    accessScopes?.includes('write_shipping') ?? false;

  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const shopifyPlan = useShopifyPlan();
  const showCheckoutSection = useHasCheckoutFunctionality();
  const isCorsoAdmin = useIsCorsoAdmin();
  const isTest = useIsTest();
  const isAdminOrTest = isCorsoAdmin || isTest;

  const displayPlanAlert = shopifyPlan === 'Basic' || shopifyPlan === 'Shopify';

  const isEligible = hasWriteShippingScope && hasDeliveryCustomizationScope;

  if (!isEligible) return <AppUpgradeRequired />;

  // ? this type could be refined
  const cartAttributeWithDescription = {
    _corsoSpTitle: {
      label: 'Shipping Plus Title',
      description: 'The Shipping Plus title applied to the cart.',
    },
    _corsoSpPrice: {
      label: 'Shipping Plus Price',
      description: 'The Shipping Plus price applied to the cart.',
    },
    _corsoSpDiscount: {
      label: 'Shipping Plus Discount',
      description: 'The Shipping Plus discount applied to the cart.',
    },
    _corsoSpDgOptOutOn: {
      label: 'Delivery Guarantee Opt Out On',
      description:
        'The timestamp when the customer opted out of the Delivery Guarantee.',
    },
  } satisfies Record<
    `_corsoSp${string}`,
    { label: string; description: string }
  >;

  const cartAttributeOption = ({
    label,
    description,
    value,
  }: {
    label: string;
    description: string;
    value: string;
  }) => (
    <div className="flex flex-col gap-1">
      <p>
        <span>{label}:</span> <span className="text-neutral-700">{value}</span>
      </p>
      <p className="text-xs text-neutral-500">{description}</p>
    </div>
  );

  const cartAttributesOptions = Object.entries(
    cartAttributeWithDescription,
  ).map(([value, { label, description }]) => ({
    value,
    label: cartAttributeOption({ label: `${label}`, value, description }),
  })) satisfies { value: string; label: MultiSelectOption['label'] }[];

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...methods}>
      <Page
        title="Shipping Plus"
        secondaryActions={[
          isAdminOrTest && {
            id: 'show-advanced-settings',
            icon: faCog,
            accessibilityLabel: 'Advanced Settings',
            onAction: () => setShowAdvancedSettings(true),
          },
          isShippingLineProtectionOffered && {
            id: 'disable-shipping-plus',
            content: 'Turn Off',

            onAction: () => {
              methods.setValue('isShippingLineProtectionOffered', false, {
                shouldDirty: true,
              });
              methods.setValue('widgetConfig.shippingPlus.isEnabled', false, {
                shouldDirty: true,
              });
              formRef.current?.requestSubmit();
            },
          },
        ].filter(isTruthy)}
        primaryAction={
          isShippingLineProtectionOffered ? undefined : (
            {
              onAction: () => {
                methods.setValue('isShippingLineProtectionOffered', true, {
                  shouldDirty: true,
                });
                methods.setValue('widgetConfig.shippingPlus.isEnabled', true, {
                  shouldDirty: true,
                });
                formRef.current?.requestSubmit();
              },
              content: 'Turn On',
            }
          )
        }
      >
        <form
          ref={formRef}
          className="flex flex-col gap-4"
          id={shipProtectSettingsFormId}
          onSubmit={submitHandler}
          onReset={(e) => {
            e.preventDefault();
            methods.reset();
          }}
        >
          <div className="flex flex-col gap-4">
            <FloatingSave
              isDirty={methods.formState.isDirty}
              isSubmitting={methods.formState.isSubmitting}
              form={shipProtectSettingsFormId}
            />

            <MerchantPaidShippingLines />

            {displayPlanAlert && (
              <Card>
                <Alert
                  variant="DEFAULT"
                  title="Shopify & Shopify Basic"
                  message="Your Shopify plan may not support the features required to use Shipping Plus. Please contact us for more information."
                />
              </Card>
            )}

            <ShippingPlusRules />
          </div>

          <ShippingRates />

          {showCheckoutSection && (
            <Card>
              <Card.Heading>Checkout Extension</Card.Heading>

              <Controller
                control={methods.control}
                name="widgetConfig.shippingPlus.isFreeShippingOptInEnabled"
                render={({ field: { onChange, value }, fieldState }) => (
                  <SwitchInput
                    id="tos-shipping-checkbox"
                    label="Delivery Guarantee Checkbox"
                    details="When enabled, customers can opt-out of the Delivery Guarantee at checkout."
                    onChange={onChange}
                    checked={!!value}
                    error={fieldState.error?.message}
                  />
                )}
              />

              <Controller
                control={methods.control}
                name="widgetConfig.shippingPlus.shouldUseDiscountedPrice"
                render={({ field: { onChange, value }, fieldState }) => (
                  <SwitchInput
                    id="use-discounted-price"
                    label="Use Discounted Price for Rate Conditions"
                    details="When enabled, Plus rate conditions will be calculated based on the discounted price of the cart. "
                    onChange={onChange}
                    checked={!!value}
                    error={fieldState.error?.message}
                  />
                )}
              />

              <Controller
                control={methods.control}
                name="widgetConfig.shippingPlus.enabledCartAttributes"
                render={({ field: f, fieldState }) => (
                  <MultiSelect
                    label="Cart Attributes"
                    options={cartAttributesOptions}
                    details="Cart attributes that appear in the order’s
                        Additional Details after checkout. More attributes
                        may be added based on enabled Shipping Plus
                        features"
                    placeholder="Add Attribute"
                    value={f.value
                      ?.map((value) =>
                        cartAttributesOptions.find(
                          (option) => option.value === `${value}`,
                        ),
                      )
                      .filter(isTruthy)}
                    onChange={(o) => f.onChange(o.map((v) => v.value))}
                    error={fieldState.error?.message}
                  />
                )}
              />

              <div className="flex items-center gap-2">
                <Card.Heading> Widget Preview </Card.Heading>
                <Action
                  icon={PencilIcon}
                  variant="ghost"
                  accessibilityLabel="Edit Widget"
                  to={`/${storeId}/settings/shipping-plus/widget`}
                />
              </div>
              <ShippingPlusWidgetPreview
                modal={methods.watch('widgetConfig.modal')}
                description={methods.watch(
                  'widgetConfig.shippingPlus.description',
                )}
                label={methods.watch('widgetConfig.shippingPlus.label')}
              />
            </Card>
          )}
        </form>
      </Page>
      <AdvancedSettings
        show={showAdvancedSettings}
        onClose={() => setShowAdvancedSettings(false)}
      />
    </FormProvider>
  );
}
