import { useMerchantContext } from '~/providers/MerchantProvider';

export function useStoreTimezone() {
  const {
    storeUser: {
      store: { ianaTimezone },
    },
  } = useMerchantContext();

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return ianaTimezone ?? userTimeZone;
}
