import { ChangeEvent } from 'react';

/**
 * Given an change `event` for an input, extract the value as a number, or `null` if the value is not a number.
 */
// TODO better name
export function getNullableNumberValue(event: ChangeEvent<HTMLInputElement>) {
  const { valueAsNumber } = event.target;
  return Number.isNaN(valueAsNumber) ? null : valueAsNumber;
}
