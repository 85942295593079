import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  CrewClaimResolutionMethodEnum,
  CrewMerchantUi,
  FinalizationEmailTypeEnum,
} from 'corso-types';
import { useMemo, useState } from 'react';
import { Controller, useFormState, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import api from '~/api';
import { SwitchInput, TextAreaInput, TextInput } from '~/components/field';
import Modal from '~/components/Modal';
import { Action } from '~/components/ui/Action';
import SimpleSelect from '~/components/ui/SimpleSelect';
import { useConfigSettings } from '~/hooks/useConfigSettings';
import { useStoreId } from '~/hooks/useStoreId';
import {
  ClaimReview,
  isMetaStatus,
  isResolvedAs,
  reviewableResolutionMethods,
  ReviewMetaStatus,
  useClaimReviewContext,
} from '~/providers/ClaimReviewProvider';
import { useMerchantContext } from '~/providers/MerchantProvider';

type EmailTemplate =
  CrewMerchantUi.SettingsConfig['email']['storeEmailTemplates']['finalizeEmailTemplates'][number];

export default function FinalizeClaim({
  show,
  onClose,
}: {
  show: boolean;
  onClose: () => void;
}) {
  const storeId = useStoreId();
  const { claimId } = useParams();
  const { claimReview } = useClaimReviewContext();
  const { userFullName } = useMerchantContext();

  const [addCustomerNote, setAddCustomerNote] = useState(false);
  const queryClient = useQueryClient();

  // TODO review and see what happens when part of a claim failed to finalize, and we retry
  const { mutate: finalizeClaim, isPending } = useMutation({
    mutationKey: ['claimFinalize', { claimId, storeId }],
    mutationFn: ({
      claim,
      isNotifyCustomerByEmailEnabled,
      notifyCustomerByEmail,
    }: ClaimReview) => {
      const { reviewLineItems, id, ...otherClaimData } = claim;

      const allReviewLineItems = reviewableResolutionMethods
        .flatMap((resolutionMethod) => reviewLineItems[resolutionMethod])
        .filter(
          (reviewLineItem) =>
            reviewLineItem.reviewMetadata.status !==
            ReviewMetaStatus.alreadyResolved,
        );

      return api
        .store(storeId)
        .claim(`${id}`, userFullName)
        .finalize({
          claim: {
            ...otherClaimData,
            wasOriginalOrderModified: otherClaimData.wasOriginalOrderModified,
            id,
            claimLineItems: {
              approved: {
                refunds: allReviewLineItems
                  .filter(isResolvedAs(CrewClaimResolutionMethodEnum.refund))
                  .map(({ reviewMetadata, noteToCustomer, claimLineItem }) => ({
                    ...claimLineItem,
                    noteToCustomer: noteToCustomer ?? undefined,
                    ...reviewMetadata.approval,
                  })),
                giftCards: allReviewLineItems
                  .filter(isResolvedAs(CrewClaimResolutionMethodEnum.giftCard))
                  .map(({ reviewMetadata, noteToCustomer, claimLineItem }) => ({
                    ...claimLineItem,
                    noteToCustomer: noteToCustomer ?? undefined,
                    ...reviewMetadata.approval,
                  })),
                variantExchanges: allReviewLineItems
                  .filter(
                    isResolvedAs(CrewClaimResolutionMethodEnum.variantExchange),
                  )
                  .map(({ reviewMetadata, noteToCustomer, claimLineItem }) => ({
                    ...claimLineItem,
                    noteToCustomer: noteToCustomer ?? undefined,
                    ...reviewMetadata.approval,
                  })),
                replacementOrders: allReviewLineItems
                  .filter(
                    isResolvedAs(
                      CrewClaimResolutionMethodEnum.replacementOrder,
                    ),
                  )
                  .map(({ reviewMetadata, noteToCustomer, claimLineItem }) => ({
                    ...claimLineItem,
                    noteToCustomer: noteToCustomer ?? undefined,
                    ...reviewMetadata.approval,
                  })),
              },
              denied: allReviewLineItems
                .filter(isMetaStatus(ReviewMetaStatus.denying))
                .map(({ reviewMetadata, noteToCustomer, claimLineItem }) => ({
                  ...claimLineItem,
                  noteToCustomer: noteToCustomer ?? undefined,
                  ...reviewMetadata.denial,
                })),
            },
          },
          ...(isNotifyCustomerByEmailEnabled && { notifyCustomerByEmail }),
        });
    },
    onSettled: () => onClose(),
    onSuccess: (data) => {
      queryClient.setQueryData(
        ['claim', { claimId, storeId }, userFullName],
        data,
      );
      // * invalidate the claims query to update the  claims list
      return queryClient.invalidateQueries({ queryKey: ['claims'] });
    },
  });

  // * There seemed to be a disconnect with claimReview formState as it was behind a render or wouldn't trigger the modal to re-render, using the useFormState hook seems to fix this
  const { errors } = useFormState({
    control: claimReview.control,
    name: [
      'notifyCustomerByEmail.subject',
      'notifyCustomerByEmail.header',
      'notifyCustomerByEmail.body',
    ],
  });
  const isEmailEnabled = useWatch({
    control: claimReview.control,
    name: 'isNotifyCustomerByEmailEnabled',
  });
  const { data: emailSettings } = useConfigSettings(({ email }) => email);
  const emailTemplateOptions = useMemo(
    () =>
      emailSettings?.storeEmailTemplates.finalizeEmailTemplates.filter(
        (template) =>
          template.type !== FinalizationEmailTypeEnum.claimNeedsMoreInfo,
      ) ?? [],
    [emailSettings?.storeEmailTemplates.finalizeEmailTemplates],
  );

  const onEmailTemplateChange = (template: EmailTemplate | null) => {
    if (!template) {
      claimReview.resetField('notifyCustomerByEmail.subject');
      claimReview.resetField('notifyCustomerByEmail.header');
      claimReview.resetField('notifyCustomerByEmail.body');
      return;
    }

    claimReview.setValue('notifyCustomerByEmail.subject', template.subject, {
      shouldDirty: true,
      shouldValidate: true,
    });
    claimReview.setValue('notifyCustomerByEmail.header', template.header, {
      shouldDirty: true,
      shouldValidate: true,
    });
    claimReview.setValue('notifyCustomerByEmail.body', template.body, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const onSubmit = () => {
    claimReview
      .handleSubmit((data) => {
        finalizeClaim(data);
        claimReview.reset();
      })()
      .catch((error) => {
        console.error('Request Failed', error);
      });
  };

  return (
    <Modal
      show={show}
      title="Finalize Claim"
      onClose={onClose}
      actions={
        <>
          <Action onClick={onClose}>Cancel</Action>
          <Action
            variant="primary"
            onClick={onSubmit}
            loading={claimReview.formState.isSubmitting || isPending}
          >
            Finalize
          </Action>
        </>
      }
    >
      <form className="flex flex-col gap-6">
        <Controller
          control={claimReview.control}
          name="isNotifyCustomerByEmailEnabled"
          render={({ field: { onChange, value } }) => (
            <SwitchInput
              id="notify-customer"
              label="Send Customer Email Notification"
              checked={value}
              onChange={onChange}
            />
          )}
        />
        {isEmailEnabled && (
          <div className="flex flex-col gap-4">
            {emailTemplateOptions.length > 0 && (
              <Controller
                control={claimReview.control}
                name="notifyCustomerByEmail.emailType"
                render={({ field: { value, onChange } }) => (
                  <SimpleSelect
                    label="Email Template"
                    options={emailTemplateOptions.map((template) => ({
                      label: template.name,
                      value: template.type,
                    }))}
                    value={value}
                    onChange={(templateType) => {
                      const template = emailTemplateOptions.find(
                        ({ type }) => type === templateType,
                      );
                      if (!template) {
                        console.error(
                          new Error(
                            `Template Not Found for Type: ${templateType}`,
                          ),
                        );
                        return; // maybe this should throw the error instead of logging and returning
                      }
                      onEmailTemplateChange(template);
                      onChange(templateType);
                    }}
                  />
                )}
              />
            )}
            <TextInput
              id="notify-customer-subject"
              label="Subject"
              required
              {...claimReview.register('notifyCustomerByEmail.subject')}
              error={
                errors.notifyCustomerByEmail?.subject && 'Subject is required.'
              }
            />
            <TextInput
              id="notify-customer-header"
              label="Header"
              required
              {...claimReview.register('notifyCustomerByEmail.header')}
              error={
                errors.notifyCustomerByEmail?.header && 'Header is required.'
              }
            />
            <TextAreaInput
              id="notify-customer-body"
              label="Body"
              required
              {...claimReview.register('notifyCustomerByEmail.body')}
              error={errors.notifyCustomerByEmail?.body && 'Body is required.'}
            />
          </div>
        )}

        <SwitchInput
          id="add-customer-note"
          label="Add Customer Note"
          checked={addCustomerNote}
          onChange={() => {
            setAddCustomerNote((prev) => !prev);
            if (!addCustomerNote) {
              claimReview.setValue('claim.noteToCustomer', '');
            }
          }}
        />

        {addCustomerNote && (
          <TextAreaInput
            id="claim-level-note-to-customer"
            label="Customer Note"
            details="This note displays at the top of the customer's claim status page. This does not replace notes added for individual line items."
            {...claimReview.register('claim.noteToCustomer')}
          />
        )}
      </form>
    </Modal>
  );
}
