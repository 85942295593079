import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';
import { forwardRef } from 'react';

import { cn } from '~/utils/shadcn';

// TODO have this primitive replace our current `Badge` component

const badgeVariants = cva(
  'inline-flex items-center gap-1 rounded-xl px-2.5 py-1 text-xs transition-colors',
  {
    variants: {
      variant: {
        default: 'bg-corso-gray-200 text-corso-gray-800',
        warning: 'bg-orange-200 text-orange-800',
        success: 'bg-emerald-100 text-emerald-800',
        danger: 'bg-corso-red-100 text-corso-red-800',
        info: 'bg-corso-blue-100 text-corso-blue-800',
      },
      hoverable: { true: null }, // providing a variant to be used as part of a compound variant
    },
    compoundVariants: [
      {
        variant: 'default',
        hoverable: true,
        className: 'hover:bg-corso-gray-300',
      },
      {
        variant: 'warning',
        hoverable: true,
        className: 'hover:bg-orange-300',
      },
      {
        variant: 'success',
        hoverable: true,
        className: 'hover:bg-emerald-200',
      },
      {
        variant: 'danger',
        hoverable: true,
        className: 'hover:bg-corso-red-300',
      },
      {
        variant: 'info',
        hoverable: true,
        className: 'hover:bg-corso-blue-400',
      },
    ],
    defaultVariants: {
      variant: 'default',
      hoverable: false,
    },
  },
);

export type BadgeProps = React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof badgeVariants>;

const Badge = forwardRef<HTMLSpanElement, BadgeProps>(
  ({ className, variant, hoverable, ...props }: BadgeProps, ref) => (
    <span
      className={cn(badgeVariants({ variant, hoverable }), className)}
      ref={ref}
      {...props}
    />
  ),
);

export { Badge, badgeVariants };
