export class UnsafeContextError<Context = unknown> extends Error {
  context?: Context;

  /**
   * An error that has some arbitrary metadata for `context`.
   * The `context` is truly arbitrary and may not necessarily be simply serializable.
   * So, use with caution.
   */
  constructor(message: string, context: Context) {
    super(message);
    this.name = 'UnsafeContextError';
    this.context = context;
  }

  readonly [Symbol.toStringTag] = 'UnsafeContextError';
}
