import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { ComponentProps, ReactNode, useState } from 'react';

import { cn } from '~/utils/shadcn';
import { Button } from './primitives/Button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from './primitives/Command';
import { Popover, PopoverContent, PopoverTrigger } from './primitives/Popover';

// TODO add and verify unbounded generic type `T` works as expected with non-string values
// TODO add `label` and `labelVisuallyHidden` props with associated `Label` component to match other field-like components
// TODO add optional `details` prop to match other field-like components

/**
 * The `value` of each option is expected to be unique, as it is also used as the `key`.
 * The `label` can be any `ReactNode` to represent the option in the UI; however, it's suggested to keep it mostly prose.
 */
export type ComboboxOption<T extends string = string> = {
  value: T;
  keywords?: string[];
  label: ReactNode;
};

export function Combobox<T extends string>({
  label,
  options,
  value,
  onChange,

  emptyLabel = 'Nothing found.',
  placeholder,

  align,
}: {
  label: string;

  options: ComboboxOption<T>[];

  value?: T;
  onChange?: NoInfer<(value?: T) => void>;

  emptyLabel?: string;
  placeholder?: string;

  align?: ComponentProps<typeof PopoverContent>['align'];
}) {
  const [open, setOpen] = useState(false);
  return (
    <Popover open={open} onOpenChange={setOpen} modal>
      <PopoverTrigger asChild>
        <Button
          role="combobox"
          aria-expanded={open}
          className="justify-between"
          aria-label={label}
        >
          {value ?
            options.find((option) => option.value === value)?.label
          : label}
          <ChevronDownIcon
            className={cn(
              'ml-2 h-4 w-4 shrink-0 opacity-50 transition-all duration-100',
              open && '-rotate-180',
            )}
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0" align={align}>
        <Command>
          <CommandInput
            placeholder={placeholder}
            className="border-none outline-none ring-0 focus:ring-0"
          />
          <CommandList>
            <CommandEmpty>{emptyLabel}</CommandEmpty>
            <CommandGroup>
              {options.map((option) => (
                <CommandItem
                  key={option.value}
                  value={option.value}
                  keywords={option.keywords}
                  onSelect={() => {
                    onChange?.(
                      option.value === value ? undefined : option.value,
                    );
                    setOpen(false);
                  }}
                >
                  <CheckIcon
                    className={cn(
                      'mr-2 h-4 w-4 shrink-0',
                      value === option.value ? 'opacity-100' : 'opacity-0',
                    )}
                  />
                  {option.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
