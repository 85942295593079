import { faTruckBolt } from '@fortawesome/pro-light-svg-icons';
import {
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import {
  CrewClaimResolutionMethodEnum,
  CrewMerchantUi,
  ShipmentMethod,
} from 'corso-types';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import api from '~/api';
import Address from '~/components/Address';
import Alert from '~/components/Alert';
import Card from '~/components/Card';
import ConfirmModal from '~/components/ConfirmModal';
import { NumberInput } from '~/components/field';
import Modal from '~/components/Modal';
import RelativeDateTime from '~/components/RelativeDateTime';
import { Action } from '~/components/ui/Action';
import SimpleSelect from '~/components/ui/SimpleSelect';
import { useIntegrationSettingsData } from '~/hooks/useIntegrationSettings';
import { useReturnLocations } from '~/hooks/useReturnLocations';
import { useStoreId } from '~/hooks/useStoreId';
import { useToast } from '~/hooks/useToast';
import {
  useClaimReviewContext,
  useInvalidateClaimReview,
} from '~/providers/ClaimReviewProvider';
import { useMerchantContext } from '~/providers/MerchantProvider';
import {
  claimShipmentCreate,
  ClaimShipmentCreate,
  ClaimShipmentQuote,
  ClaimShipmentQuoteAndCreate,
  shipmentTypeLabels,
} from '~/types';
import { isErrorMessage } from '~/utils/errorResponse';
import { formatter } from '~/utils/formatter';
import ShipmentLabel from './ShipmentLabel';
import ShipmentPackingSlip from './ShipmentPackingSlip';

type AddressWithInternalName = CrewMerchantUi.ReturnLocation['address'] & {
  internalName?: string;
};

const rmaProviderLabel = {
  Ship_Hero: 'ShipHero',
  Ship_Bob: 'ShipBob',
  Blue_Box: 'BlueBox',
  Xb_Fulfillment: 'XB Fulfillment',
} satisfies Record<
  'Ship_Hero' | 'Ship_Bob' | 'Blue_Box' | 'Xb_Fulfillment',
  string
>;

function gramsToOunces(grams: number) {
  return grams / 28.3495;
}

function ClaimQuickShip({
  toAddress,
  fromAddress,
  show,
  claimId,
  onClose,
}: {
  toAddress?: AddressWithInternalName;
  fromAddress: AddressWithInternalName;
  claimId: number;
  show: boolean;
  onClose: () => void;
}) {
  const storeId = useStoreId();
  const toast = useToast();

  const { userFullName } = useMerchantContext();

  const invalidateClaim = useInvalidateClaimReview();

  const { mutate: createAndBuyShipment, isPending } = useMutation({
    mutationFn: (body: ClaimShipmentQuoteAndCreate) =>
      api
        .store(storeId)
        .claim(`${claimId}`, userFullName)
        .quoteAndCreateShipment(body),
    onError: (error) => {
      const message =
        isErrorMessage(error) ?
          error.message
        : 'Something went wrong. Please try again.';

      onClose();
      toast.show(message);
    },
    onSuccess: () => {
      onClose();
      invalidateClaim().catch((e) => {
        // TODO: is this correct?
        console.error('Error invalidating claim review:', e);
      });
    },
  });

  // TODO: Look at the address types and make addressId required
  if (!toAddress || !toAddress.id || !fromAddress.id) return null;

  const toAddressId = toAddress.id;
  const fromAddressId = fromAddress.id;

  return (
    <ConfirmModal
      title="Quick Ship"
      prompt={
        <>
          <div className="mb-4 flex items-center">
            <p className="text-sm">
              Return shipment will be generated using the default shipping
              provider, and the cheapest postage rate will be selected.
            </p>
          </div>
          <div className="flex items-center justify-center gap-10 text-xs text-corso-gray-500">
            <div>
              <p className="text-sm font-bold">From:</p>
              <Address name={fromAddress.name} address={fromAddress} />
            </div>

            <ArrowRightIcon className="h-6 w-6" />

            <div>
              <p className="text-sm font-bold">To:</p>
              <Address name={toAddress.name} address={toAddress} />
            </div>
          </div>
        </>
      }
      confirmText="Ship It"
      cancelText="Cancel"
      variant="primary"
      show={show}
      loading={isPending}
      onConfirm={() => {
        createAndBuyShipment({
          toAddressId,
          fromAddressId,
        });
      }}
      onCancel={onClose}
    />
  );
}

function CreateClaimShipment({
  show,
  onClose,
  addresses,
}: {
  show: boolean;
  onClose: () => void;
  addresses: AddressWithInternalName[];
}) {
  const { claimReview } = useClaimReviewContext();
  const { userFullName } = useMerchantContext();

  const invalidateClaim = useInvalidateClaimReview();
  const storeId = useStoreId();
  const claim = claimReview.watch('claim');
  const toast = useToast();

  const {
    [CrewClaimResolutionMethodEnum.warrantyReview]: warrantyLi,
    [CrewClaimResolutionMethodEnum.refund]: refundLi,
    [CrewClaimResolutionMethodEnum.variantExchange]: exchangeLi,
    [CrewClaimResolutionMethodEnum.giftCard]: storeCreditLi,
  } = claim.reviewLineItems;

  const allLineItems = [
    ...warrantyLi,
    ...refundLi,
    ...exchangeLi,
    ...storeCreditLi,
  ];

  const totalWeightInOz = gramsToOunces(
    allLineItems.reduce(
      (acc, item) => acc + item.claimLineItem.originalStoreOrderLineItem.grams,
      0,
    ),
  );

  const [currentStep, setCurrentStep] = useState(0);

  const formId = 'create-claim-shipment-form';
  const {
    formState: { errors },
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
  } = useForm<ClaimShipmentCreate>({
    resolver: zodResolver(claimShipmentCreate),
    defaultValues: {
      claimId: claim.id,
      returnShipmentType: ShipmentMethod.packingSlip,
      // defaulting the from address to the claim's shipping address
      fromAddressId: claim.shippingAddress.id,
      // defaulting the to address to the first available address
      toAddressId: addresses[0]?.id,
    },
  });

  const closeAndReset = () => {
    onClose();
    reset();
    setCurrentStep(0);
  };

  const goBack = () => {
    reset();
    setCurrentStep(0);
  };

  const { mutateAsync: createReturnShipment, isPending } = useMutation({
    mutationFn: (body: ClaimShipmentCreate) =>
      api
        .store(storeId)
        .claim(`${claim.id}`, userFullName)
        .createShipment(body)
        .catch((e) => {
          const message =
            isErrorMessage(e) ?
              e.message
            : 'Something went wrong. Please try again.';
          toast.show(message);
        }),
    onSuccess: () => invalidateClaim(),
  });

  const [packageWeight, setPackageWeight] = useState(
    Math.round(totalWeightInOz),
  );

  const {
    mutateAsync: getShipmentQuotes,
    isPending: isRatesPending,
    data: quoteResp,
  } = useMutation({
    mutationFn: (body: ClaimShipmentQuote) =>
      // TODO verify what happens when a shipping provider is not available; i.e. has been removed/disconnected
      api
        .store(storeId)
        .claim(`${claim.id}`, userFullName)
        .createShipmentQuote(body),
  });

  useEffect(() => {
    if (quoteResp?.kind === 'failure' || !quoteResp) return;

    setValue('shipmentIdFromPlatform', quoteResp.shipmentIdFromPlatform);
  }, [quoteResp, setValue]);

  const selectedToAddressId = watch('toAddressId');
  const selectedFromAddressId = watch('fromAddressId');
  const selectedType = watch('returnShipmentType');
  const selectedRate = watch('rate');

  const toAddress = addresses.find((loc) => loc.id === selectedToAddressId);
  const fromAddress = addresses.find((loc) => loc.id === selectedFromAddressId);

  const rates = quoteResp?.kind === 'success' ? quoteResp.rates : [];

  const rateErrors = quoteResp?.kind === 'failure' ? quoteResp.errors : [];

  const isSameAddress = selectedToAddressId === selectedFromAddressId;

  const isManualShipment = selectedFromAddressId !== claim.shippingAddress.id;

  return (
    <Modal
      title="Create Shipment"
      show={show}
      onClose={closeAndReset}
      actions={
        <>
          <Action onClick={currentStep === 0 ? closeAndReset : goBack}>
            {currentStep === 0 ? 'Cancel' : 'Back'}
          </Action>

          {/* // create a label */}
          {selectedType === ShipmentMethod.packingSlip && (
            <Action
              variant="primary"
              type="submit"
              form={formId}
              disabled={isPending || isSameAddress}
              loading={isPending}
            >
              Create
            </Action>
          )}

          {selectedType === ShipmentMethod.label &&
            (currentStep === 0 ?
              <Action
                variant="primary"
                type="submit"
                disabled={
                  isRatesPending || !selectedToAddressId || isSameAddress
                }
                loading={isRatesPending}
                onClick={() => {
                  getShipmentQuotes({
                    toAddressId: selectedToAddressId,
                    fromAddressId: selectedFromAddressId,
                    weightInOunces: packageWeight,
                  })
                    .then(() => {
                      setCurrentStep(1);
                    })
                    .catch((e) => {
                      console.error(`Error getting shipment quotes:`, e);
                    });
                }}
              >
                Next
              </Action>
            : <Action
                variant="primary"
                type="submit"
                form={formId}
                disabled={isPending || !selectedRate || isSameAddress}
                loading={isPending}
              >
                Create
              </Action>)}
        </>
      }
    >
      <form
        id={formId}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(async (submitPayload) => {
          const { returnShipmentType, toAddressId, fromAddressId } =
            submitPayload;

          if (returnShipmentType === ShipmentMethod.label) {
            const { rate, shipmentIdFromPlatform } = submitPayload;
            await createReturnShipment({
              claimId: claim.id,
              toAddressId,
              fromAddressId,
              returnShipmentType,
              shipmentIdFromPlatform,
              rate,
            });
          }

          if (returnShipmentType === ShipmentMethod.packingSlip) {
            await createReturnShipment({
              claimId: claim.id,
              toAddressId,
              fromAddressId,
              returnShipmentType,
            });
          }
          closeAndReset();
        })}
        className="flex flex-col gap-4"
      >
        {currentStep !== 1 && (
          <>
            <Controller
              name="fromAddressId"
              control={control}
              render={({ field: { onChange, value }, fieldState }) => (
                <SimpleSelect
                  label="From"
                  options={addresses.map((location) => ({
                    label:
                      location.internalName ?
                        `${location.name} - ${location.internalName}`
                      : location.name,
                    value: `${location.id}`,
                  }))}
                  value={`${value}`}
                  onChange={(selected) =>
                    onChange(Number.parseInt(selected, 10))
                  }
                  details="The from address that will be used for this shipment."
                  error={fieldState.error?.message}
                />
              )}
            />
            <Controller
              name="toAddressId"
              control={control}
              render={({ field: { onChange, value }, fieldState }) => (
                <SimpleSelect
                  label="To"
                  options={addresses.map((location) => ({
                    label:
                      location.internalName ?
                        `${location.name} - ${location.internalName}`
                      : location.name,
                    value: `${location.id}`,
                  }))}
                  value={`${value}`}
                  onChange={(selected) =>
                    onChange(Number.parseInt(selected, 10))
                  }
                  details="The to address that will be used for this shipment."
                  error={fieldState.error?.message}
                />
              )}
            />
          </>
        )}

        {isSameAddress && (
          <Alert
            title="Address Issue"
            message="The from and to addresses are the same."
            variant="warning"
          />
        )}

        {isManualShipment && !isSameAddress && (
          <Alert
            title="Manual Shipment"
            message="This is a manual shipment, and will not trigger Automation workflows."
            variant="info"
          />
        )}
        {toAddress && fromAddress && (
          <div className="flex items-center justify-center gap-10 text-xs text-corso-gray-500">
            <div>
              <p className="text-sm font-bold">From:</p>
              <Address name={fromAddress.name} address={fromAddress} />
            </div>

            <ArrowRightIcon className="h-6 w-6" />

            <div>
              <p className="text-sm font-bold">To:</p>
              <Address name={toAddress.name} address={toAddress} />
            </div>
          </div>
        )}

        {currentStep === 0 ?
          <>
            <Controller
              name="returnShipmentType"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SimpleSelect
                  label="Return Method"
                  options={Object.values(ShipmentMethod).map(
                    (shipmentType) => ({
                      label: shipmentTypeLabels[shipmentType],
                      value: shipmentType,
                    }),
                  )}
                  value={value}
                  onChange={onChange}
                  details="The type of return shipment that will be created."
                  error={errors.returnShipmentType?.message}
                />
              )}
            />
            {selectedType === ShipmentMethod.label && (
              <NumberInput
                id="package-weight-oz"
                required
                label="Weight"
                addon={{ insideEnd: 'oz' }}
                details="The combined weight of the items in the shipment."
                onChange={(e) => setPackageWeight(e.target.valueAsNumber)}
                value={Number.isNaN(packageWeight) ? null : packageWeight}
              />
            )}
          </>
        : !isRatesPending &&
          rates.length > 1 && (
            <Controller
              name="rate"
              control={control}
              render={({ field: { onChange, value }, fieldState }) => {
                const parsedValue =
                  claimShipmentCreate.options[1].shape.rate.safeParse(
                    // part of the issue here is that there's no value set, but type-wise it's expected to exist at this point
                    // TODO determine why `value` seems to be set as a `string`, widening type to `unknown` to enforce resolution, and generally resolve to remove this
                    value as unknown,
                  );

                return (
                  <SimpleSelect
                    label="Rate"
                    placeholder="Select a rate"
                    options={rates.map((rate) => ({
                      // TODO propagate the currency code from the rate and format it accordingly
                      label: `${rate.carrier} - ${rate.service} - ${formatter.currency(rate.cost, 'USD')}`,
                      value: rate.id,
                    }))}
                    value={parsedValue.data?.id}
                    onChange={(selected) =>
                      onChange(rates.find((rate) => rate.id === selected))
                    }
                    details="The rate that will be used for the shipment."
                    error={fieldState.error?.message}
                  />
                );
              }}
            />
          )
        }
      </form>

      {!isRatesPending && rateErrors.length !== 0 && currentStep === 1 && (
        <div className="mt-4">
          <Alert
            title="Failed to Create Shipment"
            variant="danger"
            message={
              <div className="mt-2">
                <ul className="flex flex-col gap-2">
                  {rateErrors.map((item) => (
                    <li key={item}>{item}</li>
                  ))}
                </ul>
              </div>
            }
          />
        </div>
      )}
    </Modal>
  );
}

function ClaimShipmentDisplay({
  shipment,
}: {
  shipment?: CrewMerchantUi.ClaimShipment;
}) {
  if (!shipment) return null;

  const {
    returnShipmentType,
    rmaNumber,
    rmaProvider,
    toAddress,
    createdOn,
    returnShipmentId,
    fromAddress,
    isReturnShipment,
  } = shipment;

  const hasRma = rmaNumber && rmaProvider;

  return (
    <>
      <div className="flex flex-col justify-start gap-1">
        <Card.Heading>#{returnShipmentId}</Card.Heading>
        <p className="text-xs text-corso-gray-500">
          {' '}
          {isReturnShipment ? 'Return Shipment' : 'Manual Shipment'}{' '}
        </p>
        <RelativeDateTime
          dateTime={createdOn}
          className="text-xs text-corso-gray-500"
        />
      </div>

      <div className="grid grid-cols-[auto,1fr] gap-2">
        {!isReturnShipment && (
          <>
            <p className="text-xs text-corso-gray-500">From:</p>
            <Address address={fromAddress} name={fromAddress.name} />
          </>
        )}

        <p className="text-xs text-corso-gray-500">To:</p>
        <Address address={toAddress} name={toAddress.name} />
      </div>

      {hasRma && (
        <div className="flex flex-col justify-start gap-1">
          <Card.Heading>RMA</Card.Heading>
          <p className="text-xs text-corso-gray-500">
            {rmaProviderLabel[rmaProvider]} #{rmaNumber}
          </p>
        </div>
      )}

      {returnShipmentType === 'Label' ?
        <ShipmentLabel shipment={shipment} />
      : <ShipmentPackingSlip shipment={shipment} />}
    </>
  );
}

export default function ClaimShipmentsDisplay() {
  const { claimReview } = useClaimReviewContext();
  const claim = claimReview.getValues('claim');

  const shipments = claim.claimReturnShipments ?? [];

  const [showCreateShipment, setShowCreateShipment] = useState(false);
  const closeCreateShipment = () => setShowCreateShipment(false);

  const { data: locations, isSuccess: areLocationsLoaded } =
    useReturnLocations(); // ? might need to display an error state

  const locationAddresses = locations?.map((loc) => ({
    internalName: loc.name,
    ...loc.address,
  }));

  const [currentShipmentIndex, setCurrentShipmentIndex] = useState(0);
  const shipment = shipments[currentShipmentIndex];

  const [showQuickShip, setShowQuickShip] = useState(false);

  const { data: { easyPostConfig, vesylConfig } = {} } =
    useIntegrationSettingsData();

  const handlePrevious = () => {
    setCurrentShipmentIndex((prevIndex) =>
      prevIndex === 0 ? shipments.length - 1 : prevIndex - 1,
    );
  };

  const handleNext = () => {
    setCurrentShipmentIndex((prevIndex) =>
      prevIndex === shipments.length - 1 ? 0 : prevIndex + 1,
    );
  };

  const quickShipLocation = locations?.find((loc) => loc.isQuickShipEnabled);

  const enabledProvider = easyPostConfig || vesylConfig;

  const canQuickShip =
    areLocationsLoaded &&
    quickShipLocation &&
    enabledProvider &&
    shipments.length === 0;

  return (
    <Card>
      <p className="flex items-center justify-between text-sm font-medium">
        <span className="text-sm font-bold text-corso-gray-900 opacity-75">
          Shipments
        </span>
        <Action
          icon={PlusIcon}
          variant="ghost"
          accessibilityLabel="Create Shipment"
          onClick={() => setShowCreateShipment(true)}
        />
      </p>

      {canQuickShip ?
        <Action icon={faTruckBolt} onClick={() => setShowQuickShip(true)}>
          Quick Ship
        </Action>
      : <ClaimShipmentDisplay shipment={shipment} />}

      {shipments.length > 1 && (
        <div className="flex items-center justify-between">
          <p className="text-sm text-corso-gray-500">
            {currentShipmentIndex + 1} of {shipments.length}
          </p>
          <div className="flex space-x-2">
            <button
              type="button"
              onClick={handlePrevious}
              disabled={shipments.length <= 1}
              aria-label="Previous Shipment"
            >
              <ChevronLeftIcon className="h-5 w-5 text-corso-gray-500 hover:text-black" />
            </button>

            <button
              type="button"
              onClick={handleNext}
              disabled={shipments.length <= 1}
              aria-label="Next Shipment"
            >
              <ChevronRightIcon className="h-5 w-5 text-corso-gray-500 hover:text-black" />
            </button>
          </div>
        </div>
      )}

      {areLocationsLoaded && (
        <>
          <ClaimQuickShip
            claimId={claim.id}
            show={showQuickShip}
            toAddress={quickShipLocation?.address}
            fromAddress={{
              ...claim.shippingAddress,
              name: `${claim.shippingAddress.firstName} ${claim.shippingAddress.lastName}`,
            }}
            onClose={() => setShowQuickShip(false)}
          />
          <CreateClaimShipment
            show={showCreateShipment}
            onClose={closeCreateShipment}
            addresses={
              locationAddresses ?
                [
                  ...locationAddresses,
                  {
                    ...claim.shippingAddress,
                    // spreading the claim address last, so a location gets selected by default
                    name: `${claim.shippingAddress.firstName} ${claim.shippingAddress.lastName}`,
                  },
                ]
              : []
            }
          />
        </>
      )}
    </Card>
  );
}
