import {
  DocumentDuplicateIcon,
  EllipsisVerticalIcon,
  PencilIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { isTruthy } from 'corso-types';
import { useState } from 'react';
import ConfirmModal from '~/components/ConfirmModal';
import Menu from '~/components/Menu';
import { Action } from '~/components/ui/Action';
import {
  useProtectionRateDelete,
  useProtectionRateUpsert,
} from '~/hooks/useProtectionRates';
import { useStoreRules, useStoreRuleUpsert } from '~/hooks/useStoreRules';
import { ShippingRate } from '~/pages/settings/ShippingRate';
import { ProtectionRate, StoreRule } from '~/types';
import ShippingRateForm from './ShippingRateForm';

function getInUseRateNames({
  rate,
  storeRules,
}: {
  rate: ProtectionRate;
  storeRules: StoreRule[];
}) {
  const quoteOrderShipmentRules =
    storeRules?.filter(
      (rule) => rule.rule.event.type === `quoteOrderShipment`,
    ) ?? [];

  const modifyQuoteOrderShipmentRules =
    storeRules?.filter(
      (rule) => rule.rule.event.type === `quoteOrderShipmentModify`,
    ) ?? [];

  const quoteOrderShipmentRulesInUse = quoteOrderShipmentRules
    .map(({ name, rule: { event } }) => {
      if (
        'storeShipRateIds' in event.params &&
        event.params.storeShipRateIds.includes(rate.id)
      ) {
        return name;
      }
      return null;
    })
    .filter(isTruthy);

  const modifyQuoteOrderSHipmentInUse = modifyQuoteOrderShipmentRules
    .map(({ name, rule: { event } }) => {
      if ('checkoutRateModifications' in event.params) {
        const { checkoutRateModifications } = event.params;
        const modificationInUse = checkoutRateModifications
          .filter((mod) => mod.kind === 'ADD_OR_REMOVE_RATES')
          .find((mod) => mod.addOrRemoveStoreShipRateIds.includes(rate.id));

        return modificationInUse ? name : null;
      }
      return null;
    })
    .filter(isTruthy);

  return [
    ...modifyQuoteOrderSHipmentInUse,
    ...quoteOrderShipmentRulesInUse,
  ].filter(isTruthy);
}

function ManageShippingRate({
  rate,
  storeRuleId,
  editable,
}: {
  rate: ProtectionRate;
  storeRuleId?: number;
  editable: boolean;
}) {
  const { mutate: deleteProtectionRate } = useProtectionRateDelete();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [ruleInUseConfirmation, setRuleInUseConfirmation] = useState(false);
  const [showEdt, setShowEdit] = useState(false);

  const { mutate: upsertRate } = useProtectionRateUpsert();
  const { mutate: upsertStoreRule } = useStoreRuleUpsert();
  const { data: storeRules } = useStoreRules();

  const inUseRuleNames = getInUseRateNames({
    rate,
    storeRules: storeRules ?? [],
  });

  const handleUpdateRule = (
    rateId: number,
    ruleId: number,
    action: 'add' | 'remove',
  ) => {
    const storeRule = storeRules?.find((rule) => rule.id === ruleId);
    if (!storeRule) return;

    const { rule } = storeRule;
    if (rule.event.type !== `quoteOrderShipment`) return;

    const storeShipRateIds =
      action === 'add' ?
        Array.from(new Set([...rule.event.params.storeShipRateIds, rateId]))
      : rule.event.params.storeShipRateIds.filter((id) => id !== rateId);

    upsertStoreRule({
      ...storeRule,
      rule: {
        ...rule,
        event: {
          ...rule.event,
          params: {
            ...rule.event.params,
            storeShipRateIds,
          },
        },
      },
    });
  };

  const handleDuplicateRate = () => {
    const { id, ...newRate } = rate;
    upsertRate({
      ...newRate,
      name: `${rate.name} (Copy)`,
      ...(rate.plusName && { plusName: `${rate.plusName} (Copy)` }),
    });
  };

  const handleDeleteRate = () => {
    if (inUseRuleNames.length > 0) {
      setRuleInUseConfirmation(true);
    } else {
      setShowDeleteConfirmation(true);
    }
  };

  const shippingStoreRules =
    storeRules?.filter(
      (rule) => rule.rule.event.type === `quoteOrderShipment`,
    ) ?? [];

  const showAdd = shippingStoreRules.length > 0 && !storeRuleId && editable;

  return (
    <ShippingRate rate={rate}>
      {storeRuleId ?
        <>
          <Action
            variant="ghost"
            icon={PencilIcon}
            onClick={() => setShowEdit(true)}
            accessibilityLabel="Edit Rate"
          />
          <Action
            variant="ghost"
            icon={XMarkIcon}
            onClick={() => handleUpdateRule(rate.id, storeRuleId, 'remove')}
            accessibilityLabel="Remove from Rule"
          />
        </>
      : <>
          {showAdd && (
            <Menu
              align="end"
              buttonAs={
                <Action
                  size="lg"
                  variant="ghost"
                  icon={EllipsisVerticalIcon}
                  accessibilityLabel="Add to Rule"
                />
              }
            >
              {shippingStoreRules.map((rule) => {
                const isDisabled =
                  'storeShipRateIds' in rule.rule.event.params &&
                  rule.rule.event.params.storeShipRateIds.includes(rate.id);

                return (
                  <Menu.ItemButton
                    key={rule.id}
                    disabled={isDisabled}
                    onClick={() => handleUpdateRule(rate.id, rule.id, 'add')}
                  >
                    {isDisabled ?
                      `Added to ${rule.name}`
                    : `Add to ${rule.name}`}
                  </Menu.ItemButton>
                );
              })}
            </Menu>
          )}

          {editable && (
            <>
              <Action
                variant="ghost"
                size="lg"
                icon={PencilIcon}
                onClick={() => setShowEdit(true)}
                accessibilityLabel="Edit Rate"
              />
              <Action
                variant="ghost"
                size="lg"
                icon={DocumentDuplicateIcon}
                onClick={() => handleDuplicateRate()}
                accessibilityLabel="Duplicate Rate"
              />
              <Action
                variant="ghost"
                size="lg"
                icon={TrashIcon}
                onClick={() => handleDeleteRate()}
                accessibilityLabel="Delete Rate"
              />
            </>
          )}
        </>
      }

      <ConfirmModal
        prompt="Are you sure you want to delete this rate? This action cannot be undone."
        title="Delete Rate"
        show={showDeleteConfirmation}
        onConfirm={() => deleteProtectionRate(`${rate.id}`)}
        onCancel={() => setShowDeleteConfirmation(false)}
      />

      <ConfirmModal
        prompt={
          <div className="flex flex-col gap-2">
            <p>
              This rate is in use by the following rules, and cannot be deleted.
            </p>
            <ul>
              {inUseRuleNames.map((name) => (
                <li key={name}>{name}</li>
              ))}
            </ul>
          </div>
        }
        title="Rate in Use"
        variant="caution"
        show={ruleInUseConfirmation}
        onCancel={() => setRuleInUseConfirmation(false)}
        onConfirm={() => setRuleInUseConfirmation(false)}
      />

      <ShippingRateForm
        show={showEdt}
        onClose={() => setShowEdit(false)}
        values={rate}
      />
    </ShippingRate>
  );
}

export default function ManageShippingRates({
  rates,
  storeRuleId,
  editable,
  hidden,
}: {
  rates: ProtectionRate[];
  storeRuleId?: number;
  editable: boolean;
  hidden?: boolean;
}) {
  if (hidden) return null;
  return (
    <ul className="flex flex-col justify-between gap-px bg-neutral-200">
      {rates?.map((rate) => (
        <li key={rate.id} className="bg-white pb-2 pt-2.5">
          <ManageShippingRate
            rate={rate}
            storeRuleId={storeRuleId}
            editable={editable}
          />
        </li>
      ))}
    </ul>
  );
}
