import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import api from '~/api';
import { CustomField, CustomFieldCreate, CustomFieldUpdate } from '~/types';
import { isErrorMessage } from '~/utils/errorResponse';
import { useStoreId } from './useStoreId';
import { useToast } from './useToast';

const useQueryKey = () => {
  const storeId = useStoreId();
  return ['customFields', storeId] as const;
};

export const useCustomFields = () => {
  const queryKey = useQueryKey();

  return useQuery({
    queryKey,
    queryFn: ({ queryKey: [, storeId] }) =>
      api.store(storeId).customFields.get(),
  });
};

export const useCustomFieldsUpsert = () => {
  const queryClient = useQueryClient();
  const storeId = useStoreId();
  const queryKey = useQueryKey();
  const toast = useToast();

  return useMutation({
    mutationFn: (customField: CustomFieldUpdate | CustomFieldCreate) =>
      'id' in customField ?
        api
          .store(storeId)
          .customFields.update(String(customField.id), customField)
      : api.store(storeId).customFields.create(customField),
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    onMutate: (customField: CustomField | CustomFieldCreate) => {
      const previousCustomFields =
        queryClient.getQueryData<CustomField[]>(queryKey) ?? [];

      let updates: CustomField[] = [];
      if ('id' in customField) {
        const index = previousCustomFields.findIndex(
          (cf) => cf.id === customField.id,
        );
        updates = [...previousCustomFields];
        updates.splice(index, 1, customField);
      } else {
        updates = [
          ...previousCustomFields,
          { ...customField, id: Number.MAX_SAFE_INTEGER },
        ];
      }

      queryClient.setQueryData(queryKey, updates);

      return { previousGroups: previousCustomFields };
    },
    onError: (error, _, context) => {
      queryClient.setQueryData(queryKey, context?.previousGroups);
      const message =
        isErrorMessage(error) ?
          error.message
        : 'Something went wrong. Please try again.';

      toast.show(message);
      return error;
    },
  });
};

export const useCustomFieldsDelete = () => {
  const queryClient = useQueryClient();
  const storeId = useStoreId();
  const queryKey = useQueryKey();
  const toast = useToast();

  return useMutation({
    mutationFn: (customField: CustomField) =>
      api.store(storeId).customFields.delete(String(customField.id)),
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    onMutate: (customField: CustomField) => {
      const previousCustomFields =
        queryClient.getQueryData<CustomField[]>(queryKey) ?? [];

      queryClient.setQueryData(
        queryKey,
        previousCustomFields.filter((g) => g.id !== customField.id),
      );

      return { previousGroups: previousCustomFields };
    },
    onError: (error, _, context) => {
      queryClient.setQueryData(queryKey, context?.previousGroups);

      toast.show(
        isErrorMessage(error) ?
          error.message
        : 'Something went wrong. Please try again.',
      );
    },
  });
};
