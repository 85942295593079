import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import api from '~/api';
import { StoreUser, StoreUserCreate } from '~/types';
import { isErrorMessage } from '~/utils/errorResponse';
import { useStoreId } from './useStoreId';
import { useToast } from './useToast';

const useQueryKey = () => {
  const storeId = useStoreId();
  return ['storeUsers', storeId] as const;
};

export const useStoreUsers = () => {
  const queryKey = useQueryKey();

  return useQuery({
    queryKey,
    queryFn: ({ queryKey: [, storeId] }) => api.store(storeId).storeUser.get(),
  });
};

export const useStoreUserUpsert = () => {
  const storeId = useStoreId();
  const queryKey = useQueryKey();
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationFn: (user: StoreUser | StoreUserCreate) =>
      'id' in user ?
        api.store(storeId).storeUser.update(String(user.id), user)
      : api.store(storeId).storeUser.create(user),
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    onMutate: (user: StoreUser | StoreUserCreate) => {
      const previousUsers =
        queryClient.getQueryData<StoreUser[]>(queryKey) ?? [];

      let updates: StoreUser[] = [];
      if ('id' in user) {
        const index = previousUsers.findIndex((g) => g.id === user.id);
        updates = [...previousUsers];
        updates.splice(index, 1, user);
      } else {
        updates = [
          ...previousUsers,
          {
            ...user,
            id: Number.MAX_SAFE_INTEGER,
            idFromPlatform: crypto.randomUUID(),
          },
        ];
      }

      queryClient.setQueryData(queryKey, updates);

      return { previousUsers };
    },
    onError: (error, _, context) => {
      queryClient.setQueryData(queryKey, context?.previousUsers);

      const message =
        isErrorMessage(error) ?
          error.message
        : 'Something went wrong. Please try again.';
      toast.show(message);

      return error;
    },
  });
};

export const useStoreUserDelete = () => {
  const storeId = useStoreId();
  const queryKey = useQueryKey();
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationFn: ({ id }: StoreUser) =>
      api.store(String(storeId)).storeUser.delete(String(id)),

    onMutate: ({ id }: StoreUser) => {
      const previousUsers =
        queryClient.getQueryData<StoreUser[]>(queryKey) ?? [];

      queryClient.setQueryData(
        queryKey,
        previousUsers.filter((user) => user.id !== id),
      );

      return { previousUsers };
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    onError: (error, _, context) => {
      queryClient.setQueryData(queryKey, context?.previousUsers);

      const message =
        isErrorMessage(error) ?
          error.message
        : 'Something went wrong. Please try again.';
      toast.show(message);

      return error;
    },
  });
};

export const useResetUserPassword = () => {
  const storeId = useStoreId();
  const toast = useToast();

  return useMutation({
    mutationFn: (user: StoreUser) =>
      api.store(storeId).storeUser.resetPassword(user),
    onSuccess: () => {
      toast.show('Password reset email sent');
    },
    onError: (error) => {
      toast.show(
        isErrorMessage(error) ?
          error.message
        : 'Something went wrong. Please try again.',
      );
    },
  });
};
