// ? maybe use a data URL of an SVG for these instead to guarantee it's always available
export const IMAGE_NOT_AVAILABLE =
  'https://cdn.corso.com/img/image-not-available.jpeg';

type ImageElementProps = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

type FallbackImageProps = Omit<ImageElementProps, 'onError' | 'alt' | 'src'> & {
  src?: string | null; // additionally support `null` since it'll be defaulted here anyway
  /** `alt` text is required.
   * @see https://webaim.org/techniques/alttext/
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#alt
   * @see https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/main/docs/rules/alt-text.md
   */
  alt: string;
};

export default function FallbackImage({
  src,
  alt,
  ...props
}: FallbackImageProps) {
  return (
    <img
      src={src ?? IMAGE_NOT_AVAILABLE}
      alt={alt}
      onError={({ currentTarget }) => {
        // eslint-disable-next-line no-param-reassign
        currentTarget.onerror = null; // ? maybe someday `removeEventListener`
        // eslint-disable-next-line no-param-reassign
        currentTarget.src = IMAGE_NOT_AVAILABLE;
      }}
      {...props}
    />
  );
}
