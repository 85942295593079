import {
  CrewClaimResolutionMethodEnum,
  CrewClaimStatusCode,
  crewClaimStatuses,
  CrewClaimTypeEnum,
} from 'corso-types';
import ButtonGroup from '~/components/ButtonGroup';
import { Action } from '~/components/ui/Action';
import SimpleSelect from '~/components/ui/SimpleSelect';
import {
  canChangeResolution,
  createDenial,
  createRequestedApproval,
  createUndecided,
  hasDefaultApproval,
  ReviewLineItem,
  ReviewMetaStatus,
} from '~/providers/ClaimReviewProvider';
import ExchangeOrderLineItem from './resolutionLineItems/ExchangeOrderLineItem';
import GiftCardLineItem from './resolutionLineItems/GiftCardLineItem';
import RefundLineItem from './resolutionLineItems/RefundLineItem';
import ResolutionModifier from './resolutionModifiers/ResolutionModifier';

export const reviewMetaStatusSupportingText = {
  [ReviewMetaStatus.undecided]: 'Approval Pending',
  [ReviewMetaStatus.approvingAsRequested]: 'Approved as Requested',
  [ReviewMetaStatus.approvingWithModification]: 'Approved',
  [ReviewMetaStatus.denying]: 'Deny',
} satisfies Partial<Record<ReviewMetaStatus, string>>;

const deniedStatuses =
  crewClaimStatuses[CrewClaimStatusCode.denied].claimStatusDetail;

const DEFAULT_DENIAL_STATUS_CODE = deniedStatuses[2].code; // TODO remove magic number

type ClaimLineItemActionsProps = {
  reviewLineItem: ReviewLineItem;
  claimType: CrewClaimTypeEnum;
  onChange: (reviewLineItem: ReviewLineItem) => void;
};

export default function ClaimLineItemActions({
  reviewLineItem,
  claimType,
  onChange,
}: ClaimLineItemActionsProps) {
  const { claimLineItem, reviewMetadata } = reviewLineItem;

  return (
    <>
      {/* // ? might need a different button to visually represent when a modified approval has been confirmed */}
      <ButtonGroup>
        {claimType === CrewClaimTypeEnum.return && (
          <Action
            variant={
              (
                reviewMetadata.status ===
                  ReviewMetaStatus.approvingAsRequested &&
                claimLineItem.requestedResolutionMethodEnum ===
                  reviewMetadata.approval.resolutionMethodEnum
              ) ?
                'primary'
              : 'default'
            }
            disabled={!hasDefaultApproval(claimLineItem)}
            onClick={() => {
              if (!hasDefaultApproval(claimLineItem)) {
                throw new Error(
                  `ClaimLineItem, ${claimLineItem.id} is not resolvable.`,
                );
              }
              onChange({
                claimLineItem,
                noteToCustomer: reviewLineItem.noteToCustomer,
                reviewMetadata:
                  createRequestedApproval[
                    claimLineItem.requestedResolutionMethodEnum
                  ](claimLineItem),
              });
            }}
          >
            Approve as Requested
          </Action>
        )}

        {/* resolution can be changed to valid alternatives if any exist */}
        <Action
          disabled={!canChangeResolution(reviewLineItem)}
          variant={
            /* eslint-disable-next-line no-nested-ternary */ // TODO address and fix eventually
            reviewMetadata.status === ReviewMetaStatus.undecided ?
              claimType === CrewClaimTypeEnum.return ?
                'primary'
              : 'primary'
            : 'default'
          }
          onClick={() =>
            onChange({
              claimLineItem,
              noteToCustomer: reviewLineItem.noteToCustomer,
              reviewMetadata: createUndecided(),
            })
          }
        >
          Edit
        </Action>
        <Action
          variant={
            reviewMetadata.status === ReviewMetaStatus.denying ?
              'destructive'
            : 'default'
          }
          onClick={() =>
            // TODO if partial/modified revert to editing that instead of starting over
            onChange({
              claimLineItem,
              noteToCustomer: reviewLineItem.noteToCustomer,
              reviewMetadata: createDenial(
                claimLineItem,
                DEFAULT_DENIAL_STATUS_CODE,
              ),
            })
          }
        >
          Deny
        </Action>
      </ButtonGroup>

      {reviewMetadata.status === ReviewMetaStatus.undecided &&
        // should always be true, but need to re-evaluate to guarantee
        canChangeResolution(reviewLineItem) && (
          <ResolutionModifier
            reviewLineItem={reviewLineItem}
            onChange={onChange}
          />
        )}

      {/* // TODO clean up and ensure all modified approvals are represented appropriately */}
      {/* ===== Modified Resolution Content ===== */}
      {reviewMetadata.status === ReviewMetaStatus.approvingWithModification &&
        reviewMetadata.approval.resolutionMethodEnum ===
          CrewClaimResolutionMethodEnum.refund && (
          <RefundLineItem amount={reviewMetadata.approval.amount} />
        )}
      {reviewMetadata.status === ReviewMetaStatus.approvingWithModification &&
        reviewMetadata.approval.resolutionMethodEnum ===
          CrewClaimResolutionMethodEnum.giftCard && (
          <GiftCardLineItem amount={reviewMetadata.approval.amount} />
        )}
      {/* // TODO this could use a review of how it appears visually */}
      {reviewMetadata.status === ReviewMetaStatus.approvingWithModification &&
        reviewMetadata.approval.resolutionMethodEnum ===
          CrewClaimResolutionMethodEnum.replacementOrder && (
          <ExchangeOrderLineItem
            lineItems={reviewMetadata.approval.replacementItems.map(
              (replacementItem) => ({
                key: replacementItem.idFromPlatform,
                name: replacementItem.name,
                imageUrl: replacementItem.imgUrl,
                quantity: replacementItem.quantity,
                price: null, // TODO get a value to display the price
              }),
            )}
          />
        )}

      {/* ===== Denial Flow ===== */}
      {/* // ? enforce required reason when denying instead of a default selection */}
      {reviewMetadata.status === ReviewMetaStatus.denying && (
        <SimpleSelect
          label="Why are you denying this claim?"
          options={deniedStatuses.map((deniedStatus) => ({
            label: deniedStatus.name,
            value: deniedStatus.code,
          }))}
          value={
            deniedStatuses.find(
              (deniedStatus) =>
                deniedStatus.code ===
                reviewMetadata.denial.claimStatusDetailCode,
            )?.code ?? DEFAULT_DENIAL_STATUS_CODE
          }
          onChange={(selectedDenialStatus) =>
            onChange({
              claimLineItem,
              noteToCustomer: reviewLineItem.noteToCustomer,
              reviewMetadata: createDenial(claimLineItem, selectedDenialStatus),
            })
          }
        />
      )}
    </>
  );
}
