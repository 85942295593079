import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { WidgetConfig } from 'corso-types';
import { useState } from 'react';
import CorsoLogo from '~/components/CorsoLogo';
import Separator from '~/components/Separator';
import { Action } from '~/components/ui/Action';

type ModalValues = WidgetConfig['modal'];
export function ShippingPlusWidgetPreview({
  modal,
  label,
  description,
}: {
  modal: ModalValues;
  label: string;
  description?: string | null;
}) {
  const [showWidgetModal, setShowWidgetModal] = useState(false);

  return (
    <div>
      <div className="flex max-w-2xl flex-col gap-2 rounded-md border border-gray-200 p-4">
        <div className="flex items-center justify-between gap-2">
          <div className="flex items-center gap-2">
            <p className="text-sm font-medium text-black">{label}</p>
            <Action
              variant="ghost"
              icon={InformationCircleIcon}
              accessibilityLabel="Learn More"
              onClick={() => setShowWidgetModal(!showWidgetModal)}
            />
          </div>

          <CorsoLogo size="sm" />
        </div>
        {description && (
          <p className="text-xs text-corso-gray-600"> {description}</p>
        )}
      </div>

      <Action
        onClick={() => setShowWidgetModal(!showWidgetModal)}
        variant="link"
      >
        {showWidgetModal ? 'Close modal' : 'View modal'}
      </Action>

      {showWidgetModal && (
        <div className="flex max-w-2xl flex-col gap-2 rounded-md border border-gray-200 p-2">
          <div>
            {modal?.imageUrl?.length && (
              <img src={modal.imageUrl} alt="widget banner" />
            )}
            <div className="flex flex-col gap-2 p-4">
              <p className="text-center text-lg font-medium">{modal?.title}</p>
              <p className="text-sm">{modal?.description}</p>
              <Separator />
              {modal?.benefits?.map(
                ({ title, description: modalDescription }) => (
                  <div key={title} className="flex flex-col gap-1.5 p-2">
                    <p className="font-md font-medium">{title}</p>
                    <p className="text-xs">{modalDescription}</p>
                  </div>
                ),
              )}
              <Separator />
              <div className="flex justify-between gap-2 pt-2 text-xs underline">
                <p>Privacy Policy </p>|<p>CA Privacy Notice</p>|<p>Terms</p>|
                <p>Report Shipping Issue</p>
                <CorsoLogo size="base" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
