import {
  CheckIcon,
  ChevronDownIcon,
  EyeIcon,
  MagnifyingGlassIcon,
  PlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import {
  ComponentProps,
  ReactNode,
  useCallback,
  useEffect,
  useId,
  useMemo,
  useState,
} from 'react';

import { isTruthy } from 'corso-types';
import Virtualizer from '~/components/Virtualizer';
import { cn } from '~/utils/shadcn';
import { Badge } from './primitives/Badge';
import { Button } from './primitives/Button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from './primitives/Command';
import { Label } from './primitives/Label';
import { Popover, PopoverContent, PopoverTrigger } from './primitives/Popover';

function hasValue(
  options: (MultiSelectOption | MultiSelectOptionGroup)[],
  value: string,
) {
  return options.some((option) =>
    'options' in option ?
      option.options.some((groupOption) => groupOption.value === value)
    : option.value === value,
  );
}

// TODO for a11y, should this use a dropdown menu over a popover
// TODO evaluate alternatives that don't rely on so much memoization
// possibly replace search predicates with `cmdk.defaultFilter` if it's released; https://github.com/pacocoursey/cmdk/pull/229#issuecomment-2318951732

type MetaSelectOption = ComponentProps<typeof CommandItem> & { value: string };

// ? is this now just an opinionated wrapper around `Command`
export function MultiSelectContent({
  options,

  value = [],
  onChange,

  emptyLabel,
  placeholder,
  viewSelectedThreshold = 10,
  estimateSize = 32,
  creatable = false,
  clearable = false,
  togglable = false,
}: {
  options: (MultiSelectOption | MultiSelectOptionGroup)[];

  // TODO refactor `value` and `onChange` to be the value itself; similar to `Combobox` and `SimpleSelect`
  value?: MultiSelectOption[];
  onChange?: NoInfer<(value: MultiSelectOption[]) => void>;

  emptyLabel?: string;
  placeholder?: string;
  /**
   * When the number of options exceeds this value, an item will be displayed toggle viewing only the selected options.
   * @default 10
   */
  // TODO see if this can be inferred when the virtualizer is scrolling
  viewSelectedThreshold?: number;
  /**
   * The estimated size of each option when rendered.
   * @default 32
   */
  estimateSize?: number;

  creatable?: boolean;
  /** Allows clearing all selected options. */
  clearable?: boolean;
  /**
   * Allows toggling all options by way of selecting all or clearing all.
   * Using this option with `clearable` results in a clear all that's always visible, on it's own, it'll display the clear all only when all options are selected.
   */
  // TODO consider alternative names
  togglable?: boolean;
}) {
  const [inputValue, setInputValue] = useState('');

  const [createdValues, setCreatedValues] = useState<MultiSelectOption[]>(
    value.filter((v) => !hasValue(options, v.value)),
  );

  useEffect(() => {
    // given the `value` and `options` have changed, re-evaluate what is a created value including what internally might be marked as created that doesn't exist in the value or options yet
    setCreatedValues((currentCreatedValues) => {
      const createdOfValues = value.filter((v) => !hasValue(options, v.value));
      const createdInState = currentCreatedValues.filter(
        (v) =>
          !hasValue(options, v.value) &&
          !createdOfValues.some((o) => o.value === v.value),
      );
      return [...createdInState, ...createdOfValues];
    });
  }, [value, options]);

  const handleUnselect = useCallback(
    (selected: Pick<MultiSelectOption, 'value'>) => {
      onChange?.(value.filter((v) => v.value !== selected.value));
    },
    [onChange, value],
  );

  const isSelected = useCallback(
    (option: Pick<MultiSelectOption, 'value'>) =>
      value.some((selected) => selected.value === option.value),
    [value],
  );

  const [selectedOnly, setSelectedOnly] = useState(false);

  const searchValue = useMemo(
    () => inputValue.trim().toLowerCase(),
    [inputValue],
  );

  const optionSearchPredicate = useCallback(
    (option: MultiSelectOption) => {
      const comparisons = [
        option.value,
        ...(option.keywords ?? []),
        ...(typeof option.label === 'string' ? [option.label] : []),
      ];

      return comparisons.some((compare) =>
        compare.toLowerCase().includes(searchValue),
      );
    },
    [searchValue],
  );

  const groupSearchPredicate = useCallback(
    (group: MultiSelectOptionGroup) => {
      const comparisons = [group.label, ...(group.keywords ?? [])];

      return comparisons.some((compare) =>
        compare.toLowerCase().includes(searchValue),
      );
    },
    [searchValue],
  );

  const filteredOptions = useMemo(
    () =>
      options
        .map((option) => {
          // a plain option just matches the search predicate
          if ('value' in option) {
            return optionSearchPredicate(option) ? option : undefined;
          }

          // a group option matches by the predicate and has options
          if (groupSearchPredicate(option) && option.options.length)
            return option;

          // otherwise only show the group if it has a matching option
          const groupFiltered: MultiSelectOptionGroup = {
            ...option,
            options: option.options.filter(optionSearchPredicate),
          };
          return groupFiltered.options.length ? groupFiltered : undefined;
        })
        .filter(isTruthy)
        .map((option) => {
          if (!selectedOnly) return option;
          if ('value' in option) return isSelected(option) ? option : undefined;
          const groupFiltered: MultiSelectOptionGroup = {
            ...option,
            options: option.options.filter((groupOption) =>
              isSelected(groupOption),
            ),
          };
          return groupFiltered.options.length ? groupFiltered : undefined;
        })
        .filter(isTruthy),
    [
      options,
      groupSearchPredicate,
      optionSearchPredicate,
      selectedOnly,
      isSelected,
    ],
  );

  const filteredCreated = useMemo(
    () => createdValues.filter(optionSearchPredicate),
    [createdValues, optionSearchPredicate],
  );

  const filteredItems = useMemo(
    () => [filteredCreated, filteredOptions].flat(),
    [filteredCreated, filteredOptions],
  );

  const allOptions = useMemo(
    () =>
      filteredItems.flatMap((option) =>
        'options' in option ? option.options : option,
      ),
    [filteredItems],
  );

  useEffect(() => {
    const uniqueOptions = new Set(
      options.flatMap((option) =>
        'options' in option ? option.options.map((o) => o.value) : option.value,
      ),
    );
    if (uniqueOptions.size !== options.length) {
      // eslint-disable-next-line no-console
      console.warn(
        `${MultiSelectContent.name}: Duplicate values found across options, this may lead to unexpected behavior.`,
      );
    }
  }, [options]);

  useEffect(() => {
    // when there is nothing selected, default back to showing all
    if (!value.length && selectedOnly) setSelectedOnly(false);
  }, [selectedOnly, value.length]);

  const metaOptions = useMemo(
    () =>
      [
        // we may want re-evaluate so clear and select all meta can remain as one item so they share focus when toggled
        // meta item: clear
        ...(clearable || (togglable && allOptions.length === value.length) ?
          [
            {
              value: 'meta-clear',
              disabled: !value.length,
              onSelect: () => {
                onChange?.([]);
              },
              children: (
                <>
                  <XMarkIcon className="mr-2 h-4 w-4" />
                  Clear
                </>
              ),
            },
          ]
        : []),
        // meta item: toggle to select all
        ...((
          togglable && !!allOptions.length && value.length !== allOptions.length
        ) ?
          [
            {
              value: 'meta-toggle',
              onSelect: () => {
                if (value.length === allOptions.length) onChange?.([]);
                else onChange?.(allOptions);
              },
              children: (
                <>
                  <PlusIcon className="mr-2 h-4 w-4" />
                  Select All
                </>
              ),
            },
          ]
        : []),
        // meta item: create a new item
        ...((
          creatable &&
          inputValue &&
          filteredOptions.every((option) =>
            'options' in option ?
              option.options.every(
                (groupOption) => groupOption.value !== inputValue,
              )
            : option.value !== inputValue,
          )
        ) ?
          [
            {
              value: 'meta-create',
              onSelect: () => {
                const createdValue = {
                  value: inputValue,
                  label: inputValue,
                } satisfies MultiSelectOption;
                setCreatedValues([...createdValues, createdValue]);
                onChange?.([...value, createdValue]);
                setInputValue('');
              },
              children: (
                <>
                  <PlusIcon className="mr-2 h-4 w-4" />
                  Add &quot;{inputValue}&quot;
                </>
              ),
            },
          ]
        : []),
        // meta item: show/hide unselected
        ...((
          (!!value.length && allOptions.length > viewSelectedThreshold) ||
          selectedOnly
        ) ?
          [
            {
              value: 'meta-toggle-visible',
              onSelect: () => setSelectedOnly((current) => !current),
              children: (
                <>
                  <EyeIcon className="mr-2 h-4 w-4" />
                  {selectedOnly ? 'Show All' : 'Show Selected'}
                </>
              ),
            },
          ]
        : []),
      ].filter(isTruthy) satisfies MetaSelectOption[],
    [
      allOptions,
      clearable,
      creatable,
      createdValues,
      filteredOptions,
      inputValue,
      onChange,
      selectedOnly,
      togglable,
      value,
      viewSelectedThreshold,
    ],
  );

  const items = useMemo(
    () => [
      ...(!metaOptions.length ?
        []
      : [
          {
            kind: 'meta' as const,
            options: metaOptions,
          },
        ]),
      ...filteredItems.map((option) => ({
        kind: 'option' as const,
        ...option,
      })),
    ],
    [metaOptions, filteredItems],
  );

  // TODO consider moving the meta items into the virtualizer
  return (
    <Command shouldFilter={false}>
      <CommandInput
        aria-label={placeholder}
        placeholder={placeholder}
        value={inputValue}
        onValueChange={setInputValue}
        onKeyDown={(event) => {
          if (inputValue) return;
          if (!['Delete', 'Backspace'].includes(event.key)) return;

          const lastSelectOption = value.at(-1);
          if (!lastSelectOption) return;

          handleUnselect(lastSelectOption);
        }}
      />
      {/* bypass command list styling and let height be handled by the `Virtualizer` */}
      <CommandList className="contents">
        <CommandEmpty>
          {emptyLabel ??
            (creatable ?
              'Nothing found, search to create something new.'
            : 'Nothing Found.')}
        </CommandEmpty>
        {!items.length ? null : (
          <CommandGroup>
            <Virtualizer
              items={items}
              // estimated size of `CommandItem` when rendered
              estimateSize={(index) => {
                const item = items[index];
                if (item && 'options' in item) {
                  return item.options.length * estimateSize;
                }
                return estimateSize;
              }}
            >
              {(option) => {
                if (option.kind === 'meta') {
                  return (
                    <CommandGroup key={option.kind}>
                      {option.options.map((metaOption) => (
                        <CommandItem key={metaOption.value} {...metaOption} />
                      ))}
                    </CommandGroup>
                  );
                }

                if ('options' in option) {
                  return (
                    <CommandGroup key={option.label} heading={option.label}>
                      {option.options.map((groupOption) => (
                        <CommandItem
                          key={groupOption.value}
                          value={groupOption.value}
                          keywords={groupOption.keywords}
                          onSelect={() => {
                            if (isSelected(groupOption)) {
                              handleUnselect(groupOption);
                            } else onChange?.([...value, groupOption]);
                          }}
                        >
                          <CheckIcon
                            className={cn(
                              'mr-2 h-4 w-4 shrink-0',
                              isSelected(groupOption) ? 'opacity-100' : (
                                'opacity-0'
                              ),
                            )}
                          />
                          {groupOption.label}
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  );
                }
                return (
                  <CommandItem
                    key={option.value}
                    value={option.value}
                    keywords={option.keywords}
                    onSelect={() => {
                      if (isSelected(option)) {
                        handleUnselect(option);
                      } else onChange?.([...value, option]);
                    }}
                  >
                    <CheckIcon
                      className={cn(
                        'mr-2 h-4 w-4 shrink-0',
                        isSelected(option) ? 'opacity-100' : 'opacity-0',
                      )}
                    />
                    {option.label}
                  </CommandItem>
                );
              }}
            </Virtualizer>
          </CommandGroup>
        )}
      </CommandList>
    </Command>
  );
}

// TODO consider unbounded generic type `T` or bounded `T extends string` works as expected
// TODO add optional `details` prop to match other field-like components
// TODO refactor creatable item

/**
 * The `value` of each option is expected to be unique, as it is also used as the `key`.
 * The `label` can be any `ReactNode` to represent the option in the UI; however, it's suggested to keep it mostly prose.
 */
export type MultiSelectOption = {
  /** Should be unique across ALL options. */
  value: string;
  /** Aliases for the option to use while ranking and searching. */
  keywords?: string[];
  label: ReactNode;
};

/**
 * The `label` for each group is expected to be unique as it's used as the `key` to identify the group.
 */
export type MultiSelectOptionGroup = {
  /** Should be unique across groups. */
  label: string; // TODO convert to `ReactNode` and add required `key` prop for uniqueness
  /** Aliases for the group to use while ranking and searching. */
  keywords?: string[];
  options: MultiSelectOption[];
};

/**
 * Fundamentally, is an alternative to the `Combobox` component, except it allows for multiple selections, and optional creation.
 */
export function MultiSelect({
  label,
  labelVisuallyHidden = false,

  details,
  error,

  visibleLimit = 5,

  defaultOpen = false,

  // MultiSelectContent props
  value = [],
  placeholder,
  ...additionalMultiSelectContentProps
}: {
  /**
   * A label for the selection, to provide context for the user.
   * Although this accepts a `ReactNode`, it's recommended to mostly use `string` values or a fragment with prose elements.
   */
  label: ReactNode;
  // TODO consider alternative names
  labelVisuallyHidden?: boolean;

  /**
   * Descriptive information to further clarify the intended data.
   * Although this accepts a `ReactNode`, it's recommended to mostly use `string` values or a fragment with prose elements.
   */
  details?: ReactNode;
  /**
   * Descriptive information about the `error` state.
   * Although this accepts a `ReactNode`, it's recommended to mostly use `string` values or a fragment with prose elements.
   */
  error?: ReactNode;

  /**
   * The maximum number of selected options to display before truncating with a `+n` count of the remaining options.
   * @default 5
   */
  visibleLimit?: number;

  defaultOpen?: boolean;
} & ComponentProps<typeof MultiSelectContent>) {
  const id = useId();

  const [open, setOpen] = useState(defaultOpen);

  return (
    <div className="flex flex-col gap-2">
      <Label htmlFor={id} className={cn(labelVisuallyHidden && 'sr-only')}>
        {label}
      </Label>
      <Popover
        open={open}
        onOpenChange={setOpen}
        modal // capture outside interaction, useful if within another modal/dialog
      >
        <PopoverTrigger asChild id={id}>
          <Button
            role="combobox"
            aria-expanded={open}
            // may need to revisit this being full width instead of mostly intrinsic width
            className="w-full justify-between gap-2"
          >
            {value.length ?
              <div className="flex flex-wrap gap-1">
                {value.slice(0, visibleLimit).map((selected) => (
                  <Badge key={selected.value}>{selected.label}</Badge>
                ))}
                {visibleLimit && value.length > visibleLimit && (
                  <Badge variant="info">
                    +{value.length - visibleLimit} More
                  </Badge>
                )}
              </div>
            : <span
                className={cn(
                  'flex items-center gap-2 font-normal text-neutral-500',
                  open && 'invisible',
                )}
              >
                <MagnifyingGlassIcon className="h-4 w-4 shrink-0 opacity-50" />
                {placeholder}
              </span>
            }
            <ChevronDownIcon
              className={cn(
                'h-4 w-4 shrink-0 opacity-50 transition-all duration-100',
                open && '-rotate-180',
              )}
            />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="p-0">
          <MultiSelectContent
            value={value}
            placeholder={placeholder}
            {...additionalMultiSelectContentProps}
          />
        </PopoverContent>
      </Popover>
      {/* // TODO should these both use `SupportingText` */}
      {error && (
        <section className="text-xs text-corso-red-600">{error}</section>
      )}
      {details && (
        <section className="text-xs text-corso-gray-500">{details}</section>
      )}
    </div>
  );
}
