import { PlusIcon } from '@heroicons/react/20/solid';
import { Separator } from '@radix-ui/react-dropdown-menu';
import { Pegasus, isTruthy } from 'corso-types';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import Card from '~/components/Card';
import { NumberInput } from '~/components/field';
import { Action } from '~/components/ui/Action';
import { MultiSelect } from '~/components/ui/MultiSelect';
import SimpleSelect from '~/components/ui/SimpleSelect';
import { useStoreTimezone } from '~/hooks/useStoreTimezone';
import { StoreRuleFormData } from '~/types';
import {
  estimatedDeliveryShippingCutOffOptions,
  usHolidayOptions,
} from '~/utils/enumNameMaps';

export default function EventThemeAppExtensionLoad() {
  const { control, watch } = useFormContext<StoreRuleFormData>();

  const timezone = useStoreTimezone();

  const {
    fields: themeAppConfigs,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'event.params.themeAppConfigs',
  });

  const handleAddEdd = () => {
    append({
      strategy: 'STATIC',
      estimatedDeliveryRangeStartDays: 3,
      estimatedDeliveryRangeEndDays: 7,
      estimatedDeliveryShippingCutOffTime: 0,
      estimatedDeliveryFulfillmentTz: timezone,
      estimatedDeliveryHolidays: Object.values(Pegasus.USHoliday),
    });
  };

  const estimatedDeliveryConfigs = watch('event.params.themeAppConfigs');

  return (
    <div className="flex flex-col gap-2">
      {themeAppConfigs.map((field, index) => {
        const estimateStrategy = watch(
          `event.params.themeAppConfigs.${index}.strategy`,
        );

        return (
          <Card key={field.id}>
            <Card.Heading> Provide An Estimated Delivery Date</Card.Heading>

            <>
              {/* Implement Multi-Select Here once we have defined additional strategies */}

              {estimateStrategy === 'STATIC' && (
                <>
                  {/* // TODO get back to undefined after entering a value on these inputs */}
                  <Controller
                    control={control}
                    name={`event.params.themeAppConfigs.${index}.estimatedDeliveryRangeStartDays`}
                    render={({ field: f, fieldState }) => (
                      <NumberInput
                        id={`estimated-delivery-range-start-${index}`}
                        label="Estimated Delivery Start"
                        details="The number of business days to the earliest possible delivery date."
                        addon={{ insideEnd: 'days' }}
                        value={f.value}
                        error={fieldState.error?.message}
                        onChange={(e) => f.onChange(e.target.valueAsNumber)}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`event.params.themeAppConfigs.${index}.estimatedDeliveryRangeEndDays`}
                    render={({ field: f, fieldState }) => (
                      <NumberInput
                        id={`estimated-delivery-range-end-${index}`}
                        label="Estimated Delivery End"
                        details="The number of business days to the latest possible delivery date."
                        value={f.value}
                        addon={{ insideEnd: 'days' }}
                        error={fieldState.error?.message}
                        onChange={(e) => f.onChange(e.target.valueAsNumber)}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name={`event.params.themeAppConfigs.${index}.estimatedDeliveryShippingCutOffTime`}
                    render={({ field: { onChange, value }, fieldState }) => (
                      <SimpleSelect
                        options={estimatedDeliveryShippingCutOffOptions.map(
                          (option) => ({
                            value: `${option.value}`,
                            label: option.label,
                          }),
                        )}
                        details="The time of day to cut off shipping for the estimated delivery date."
                        label="Shipping Cut Off Time"
                        onChange={onChange}
                        value={`${value}`}
                        error={fieldState.error?.message}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`event.params.themeAppConfigs.${index}.estimatedDeliveryHolidays`}
                    render={({ field: f, fieldState }) => (
                      <MultiSelect
                        estimateSize={114}
                        label="Holidays"
                        options={usHolidayOptions}
                        details="The US Holidays to use when calculating the estimated delivery date."
                        placeholder="Select Holidays"
                        value={f.value
                          ?.map((value) =>
                            usHolidayOptions.find(
                              (option) => option.value === `${value}`,
                            ),
                          )
                          .filter(isTruthy)}
                        onChange={(selected) =>
                          f.onChange(
                            selected.map(({ value }) => value), // Ensure holiday values are passed back in the correct format
                          )
                        }
                        error={fieldState.error?.message}
                      />
                    )}
                  />
                </>
              )}

              {/* Implement the dynamic section here at some future point */}
            </>

            <div className="self-end">
              <Action
                onClick={() => {
                  remove(index);
                }}
                variant="destructive"
              >
                Remove
              </Action>
            </div>
          </Card>
        );
      })}

      <Separator />

      {estimatedDeliveryConfigs.length === 0 && (
        <div className="flex items-end">
          <Action
            icon={PlusIcon}
            key="Add Estimated Delivery Date"
            onClick={() => handleAddEdd()}
          >
            Add Estimated Delivery
          </Action>
        </div>
      )}
    </div>
  );
}
