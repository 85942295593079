// TODO: This file is a work in progress probably be renamed and serve as the main file for all the filter types and definitions
import {
  ClaimReasonEnum,
  ClaimResolutionMethodEnum,
  CrewClaimResolutionMethodEnum,
  crewClaimResolutionMethodEnumName,
  CrewClaimRollupStatusCode,
  crewClaimRollupStatusCodeName,
  CrewMerchantUi,
  shippingClaimReasonName,
  TrackingStatusCode,
  trackingStatusCodeName,
} from 'corso-types';
import { useMemo } from 'react';
import { match } from 'ts-pattern';
import { useStoreClaimTags } from '~/hooks/useClaimTags';
import { useConfigSettings } from '~/hooks/useConfigSettings';
import { shippingClaimResolutionMethodName } from '~/utils/enumNameMaps';
// import { DatePreset, FilterControlType, FilterTypeOptions } from './Filter';

type KeysOfUnion<T> = T extends T ? keyof T : never;
type ViewEntity = CrewMerchantUi.SearchView['kind'];

type ViewForEntity<K extends ViewEntity> = Extract<
  CrewMerchantUi.SearchView,
  { kind: K }
>;

type ViewFilterId = KeysOfUnion<ViewForEntity<ViewEntity>['filters']>;
type ViewFilterIdForEntity<E extends ViewEntity> = KeysOfUnion<
  ViewForEntity<E>['filters']
>;

export enum FilterControlType {
  text = 'text', // free text input
  date = 'date', // pre-defined date ranges or custom date range
  multiselect = 'multiselect', // checkboxes w/ search if many
  boolean = 'boolean', // true/false -- select but will be converted to boolean at the request level
}
export enum DatePreset {
  today = '0d',
  last7Days = '7d',
  last30Days = '30d',
  last90Days = '90d',
  last12Months = '12m',
  custom = 'custom',
}

export type FilterTypeOptions = {
  [FilterControlType.text]: never;
  [FilterControlType.date]: { label: string; value: DatePreset }[];
  [FilterControlType.multiselect]: { label: string; value: string }[];
  [FilterControlType.boolean]: [
    { label: string; value: true },
    { label: string; value: false },
  ];
};

const filterIdToControlType = {
  searchTerm: FilterControlType.text,
  claimReasons: FilterControlType.multiselect,
  claimTaggedWith: FilterControlType.multiselect,
  createdOn: FilterControlType.date,
  estimatedPurchaseDate: FilterControlType.date,
  hasBeenCanceled: FilterControlType.boolean,
  hasClaim: FilterControlType.boolean,
  hasError: FilterControlType.boolean,
  hasInspection: FilterControlType.boolean,
  hasInstantExchange: FilterControlType.boolean,
  hasRegistration: FilterControlType.boolean,
  salesChannels: FilterControlType.multiselect,
  requestedResolutionMethods: FilterControlType.multiselect,
  resolutionMethods: FilterControlType.multiselect,
  shipmentStatus: FilterControlType.multiselect,
  source: FilterControlType.multiselect,
  statusCodes: FilterControlType.multiselect,
  hasShipment: FilterControlType.boolean,
} as const satisfies Record<ViewFilterId, FilterControlType>;
type FilterIdControlType = typeof filterIdToControlType;

// sanity check to see what filter control types are used
export type UnusedFilterControlType = Exclude<
  FilterControlType,
  FilterIdControlType[keyof FilterIdControlType]
>;

type FilterValue<E extends ViewEntity, ID extends ViewFilterIdForEntity<E>> =
  ID extends ViewFilterIdForEntity<E> ? ViewForEntity<E>['filters'][ID] : never;
type FilterType<K extends ViewEntity, ID extends ViewFilterIdForEntity<K>> =
  ID extends keyof FilterIdControlType ? FilterIdControlType[ID] : never;

export type Filter<
  E extends ViewEntity = ViewEntity,
  ID extends ViewFilterIdForEntity<E> = ViewFilterIdForEntity<E>,
> = {
  [V in E]: {
    [K in ID]: {
      id: K;
      label: string;
      value?: FilterValue<V, K>;
      type: FilterType<V, K>;
    } & (FilterTypeOptions[FilterType<V, K>] extends never ?
      {
        options?: never;
      }
    : {
        options: FilterTypeOptions[FilterType<V, K>];
      });
  };
}[E][ID];

type Definition<E extends ViewEntity> = {
  kind: E;
  filters: { [ID in ViewFilterIdForEntity<E>]: Filter<E, ID> };
};

type ReturnFilterDefinition = Definition<'Return'>;

type WarrantyFilterDefinition = Definition<'Warranty'>;

type ShippingFilterDefinition = Definition<'Shipping'>;

type RegistrationFilterDefinition = Definition<'Registration'>;

type OrderFilterDefinition = Definition<'Order'>;

export type FilterDefinition =
  | RegistrationFilterDefinition
  | ReturnFilterDefinition
  | ShippingFilterDefinition
  | WarrantyFilterDefinition
  | OrderFilterDefinition;

const createdOn = {
  id: 'createdOn',
  label: 'Date',
  type: FilterControlType.date,
  options: [
    { value: DatePreset.today, label: 'Today' },
    { value: DatePreset.last7Days, label: 'Last 7 Days' },
    { value: DatePreset.last30Days, label: 'Last 30 Days' },
    { value: DatePreset.last90Days, label: 'Last 90 Days' },
    { value: DatePreset.last12Months, label: 'Last 12 Months' },
    { value: DatePreset.custom, label: 'Custom' },
  ],
} satisfies Filter<ViewEntity, 'createdOn'>;

const searchTerm = {
  id: 'searchTerm',
  label: 'Search Term',
  type: FilterControlType.text,
} satisfies Filter<ViewEntity, 'searchTerm'>;

const source = {
  id: 'source',
  label: 'Source',
  type: FilterControlType.multiselect,
  options: [
    { value: 'Merchant_App', label: 'Merchant App' },
    { value: 'Customer_App', label: 'Customer App' },
  ],
} satisfies Filter<'Return' | 'Warranty', 'source'>;

const shipmentStatus = {
  id: 'shipmentStatus',
  label: 'Shipment Status',
  type: FilterControlType.multiselect,
  options: Object.values(TrackingStatusCode).map((status) => ({
    value: status,
    label: trackingStatusCodeName[status],
  })),
} satisfies Filter<'Return' | 'Warranty', 'shipmentStatus'>;

const hasError = {
  id: 'hasError',
  label: 'Error',
  type: FilterControlType.boolean,
  options: [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ],
} satisfies Filter<'Return' | 'Warranty', 'hasError'>;

const hasInspection = {
  id: 'hasInspection',
  label: 'Inspection',
  type: FilterControlType.boolean,
  options: [
    {
      value: true,
      label: 'Yes',
    },
    {
      value: false,
      label: 'No',
    },
  ],
} satisfies Filter<'Return' | 'Warranty', 'hasInspection'>;

const hasShipment = {
  id: 'hasShipment',
  label: 'Shipment',
  type: FilterControlType.boolean,
  options: [
    {
      value: true,
      label: 'Yes',
    },
    {
      value: false,
      label: 'No',
    },
  ],
} satisfies Filter<'Return' | 'Warranty', 'hasShipment'>;

const statusCodes = {
  id: 'statusCodes',
  label: 'Status',
  type: FilterControlType.multiselect,
  options: Object.values(CrewClaimRollupStatusCode).map((code) => ({
    value: code,
    label: crewClaimRollupStatusCodeName[code],
  })),
} satisfies Filter<'Return' | 'Warranty', 'statusCodes'>;

const defineReturnFilters = (tags: string[]) =>
  ({
    createdOn,
    searchTerm,
    source,
    statusCodes,
    shipmentStatus,
    hasError,
    hasShipment,
    requestedResolutionMethods: {
      id: 'requestedResolutionMethods',
      label: 'Resolution Method',
      type: FilterControlType.multiselect,
      options: Object.values(CrewClaimResolutionMethodEnum)
        .filter(
          (method) =>
            method !== CrewClaimResolutionMethodEnum.repair &&
            method !== CrewClaimResolutionMethodEnum.warrantyReview,
        )
        .map((method) => ({
          value: method,
          label: crewClaimResolutionMethodEnumName[method],
        })),
    },
    claimTaggedWith: {
      id: 'claimTaggedWith',
      label: 'Tagged With',
      type: FilterControlType.multiselect,
      options: tags.map((tag) => ({ value: tag, label: tag })),
    },
    hasInspection,
    hasInstantExchange: {
      id: 'hasInstantExchange',
      label: 'Instant Exchange',
      type: FilterControlType.boolean,
      options: [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
      ],
    },
  }) satisfies ReturnFilterDefinition['filters'];

const defineWarrantyFilters = (tags: string[], salesChannels: string[]) =>
  ({
    createdOn,
    searchTerm,
    source,
    statusCodes,
    shipmentStatus,
    hasError,
    salesChannels: {
      id: 'salesChannels',
      label: 'Registration Channel',
      type: FilterControlType.multiselect,
      options: salesChannels.map((channel) => ({
        value: channel,
        label: channel,
      })),
    },
    hasRegistration: {
      id: 'hasRegistration',
      label: 'Registration',
      type: FilterControlType.boolean,
      options: [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
      ],
    },
    hasShipment,
    hasInspection,
    requestedResolutionMethods: {
      id: 'requestedResolutionMethods',
      label: 'Resolution Method',
      type: FilterControlType.multiselect,
      options: Object.values(CrewClaimResolutionMethodEnum)
        .filter(
          (method) =>
            method !== CrewClaimResolutionMethodEnum.repair &&
            method !== CrewClaimResolutionMethodEnum.variantExchange,
        )
        .map((method) => ({
          value: method,
          label: crewClaimResolutionMethodEnumName[method],
        })),
    },
    claimTaggedWith: {
      id: 'claimTaggedWith',
      label: 'Tagged With',
      type: FilterControlType.multiselect,
      options: tags.map((tag) => ({ value: tag, label: tag })),
    },
  }) satisfies WarrantyFilterDefinition['filters'];

const defineShippingFilters = (offeredClaimReasons: `${ClaimReasonEnum}`[]) =>
  ({
    createdOn,
    searchTerm,
    claimReasons: {
      id: 'claimReasons',
      label: 'Reason',
      type: FilterControlType.multiselect,
      options: offeredClaimReasons.map((offeredReason) => ({
        value: offeredReason,
        label: shippingClaimReasonName[offeredReason],
      })),
    },
    resolutionMethods: {
      id: 'resolutionMethods',
      label: 'Resolution',
      type: FilterControlType.multiselect,
      options: Object.values(ClaimResolutionMethodEnum).map((value) => ({
        value,
        label: shippingClaimResolutionMethodName[value],
      })),
    },
  }) satisfies ShippingFilterDefinition['filters'];

const defineRegistrationFilters = (salesChannels: string[]) =>
  ({
    createdOn,
    searchTerm,
    hasClaim: {
      id: 'hasClaim',
      label: 'Warranty Claim',
      type: FilterControlType.boolean,
      options: [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
      ],
    },
    hasBeenCanceled: {
      id: 'hasBeenCanceled',
      label: 'Canceled',
      type: FilterControlType.boolean,
      options: [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
      ],
    },
    estimatedPurchaseDate: {
      id: 'estimatedPurchaseDate',
      label: 'Purchase Date',
      type: FilterControlType.date,
      options: [
        { value: DatePreset.today, label: 'Today' },
        { value: DatePreset.last7Days, label: 'Last 7 Days' },
        { value: DatePreset.last30Days, label: 'Last 30 Days' },
        { value: DatePreset.last90Days, label: 'Last 90 Days' },
        { value: DatePreset.last12Months, label: 'Last 12 Months' },
        { value: DatePreset.custom, label: 'Custom' },
      ],
    },
    salesChannels: {
      id: 'salesChannels',
      label: 'Channel',
      type: FilterControlType.multiselect,
      options: salesChannels.map((channel) => ({
        value: channel,
        label: channel,
      })),
    },
  }) satisfies RegistrationFilterDefinition['filters'];

const defineOrderFilters = () =>
  ({
    createdOn,
    searchTerm,
  }) satisfies OrderFilterDefinition['filters'];

export function useFilterDefinition<Entity extends ViewEntity>(entity: Entity) {
  const { data: tags = [] } = useStoreClaimTags((claimTags) =>
    claimTags?.map((tag) => tag.name),
  );

  const { data: registrationSettings } = useConfigSettings(
    ({ registrations }) => registrations,
  );
  const { data: offeredClaimReasons } = useConfigSettings(
    ({ shippingProtection }) =>
      shippingProtection ? shippingProtection.offeredClaimReasons : [],
  );

  return useMemo(
    () =>
      match<ViewEntity>(entity)
        .returnType<FilterDefinition>()
        .with('Return', (kind) => ({
          kind,
          filters: defineReturnFilters(tags),
        }))
        .with('Warranty', (kind) => ({
          kind,
          filters: defineWarrantyFilters(
            tags,
            registrationSettings?.salesChannels ?? [],
          ),
        }))
        .with('Shipping', (kind) => ({
          kind,
          filters: defineShippingFilters(offeredClaimReasons ?? []),
        }))
        .with('Registration', (kind) => ({
          kind,
          filters: defineRegistrationFilters(
            registrationSettings?.salesChannels ?? [],
          ),
        }))
        .with('Order', (kind) => ({
          kind,
          filters: defineOrderFilters(),
        }))
        .exhaustive(),
    [tags, entity, offeredClaimReasons, registrationSettings],
  );
}
