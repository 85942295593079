import { isNullish } from 'corso-types';
import { ReactNode, useId } from 'react';
import { twMerge } from 'tailwind-merge';
import { Label } from './primitives/Label';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from './primitives/Select';

export type SimpleSelectOption<T extends string = string> = {
  /**
   * Should be unique across ALL options.
   * Used as a `key` for the option, and the `value` that it becomes when selected.
   */
  value: T;
  label: ReactNode;
};

/**
 * The `label` for each group is expected to be unique as it's used as the `key` to identify the group.
 */
export type SimpleSelectOptionGroup<T extends string = string> = {
  /** Used as a `key` for the group, should be unique across groups. */
  key: string;
  label: ReactNode;
  options: SimpleSelectOption<T>[];
};

// TODO consider alternative names
// TODO add JSDoc comment for suggested use cases and even more complex examples
// TODO add and verify unbounded generic type `T` works as expected with non-string values

export default function SimpleSelect<T extends string>({
  label,
  labelVisuallyHidden = false,
  options,

  defaultValue,
  value,
  onChange,

  defaultOpen,
  open,
  onOpenChange,

  name,
  disabled,
  required,

  placeholder = 'Select an option',
  error,
  details,
}: {
  /**
   * A label for the selection, to provide context for the user.
   * Although this accepts a `ReactNode`, it's recommended to mostly use `string` values or a fragment with prose elements.
   */
  label: ReactNode;
  // TODO consider alternative names
  labelVisuallyHidden?: boolean;
  /** Should be a stable reference to a series of objects. Otherwise, this will result in undesirable behavior, as items cannot be uniquely identified between renders based by reference. */
  options: (SimpleSelectOption<T> | SimpleSelectOptionGroup<T>)[];

  defaultValue?: T;
  value?: T | null;
  onChange?: NoInfer<(value: T) => void>;

  defaultOpen?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;

  name?: string;
  disabled?: boolean;
  required?: boolean;

  placeholder?: string;
  /**
   * Descriptive information to further clarify the intended data.
   * Although this accepts a `ReactNode`, it's recommended to mostly use `string` values or a fragment with prose elements.
   */
  details?: ReactNode;
  /**
   * Descriptive information about the `error` state.
   * Although this accepts a `ReactNode`, it's recommended to mostly use `string` values or a fragment with prose elements.
   */
  error?: ReactNode;
}) {
  const id = useId();

  return (
    <div className="flex flex-col gap-2">
      <Label htmlFor={id} className={twMerge(labelVisuallyHidden && 'sr-only')}>
        {label}
      </Label>
      <Select
        defaultValue={defaultValue}
        value={isNullish(value) ? '' : value} // reset on nullish values to empty string, also maintains controlled state
        onValueChange={onChange}
        defaultOpen={defaultOpen}
        open={open}
        onOpenChange={onOpenChange}
        name={name}
        disabled={disabled}
        required={required}
      >
        <SelectTrigger id={id}>
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent>
          {options.map((option) => {
            if ('options' in option) {
              return (
                <SelectGroup key={option.key}>
                  <SelectLabel>{option.label}</SelectLabel>
                  {option.options.map((groupOption) => (
                    <SelectItem
                      key={groupOption.value}
                      value={groupOption.value}
                    >
                      {groupOption.label}
                    </SelectItem>
                  ))}
                </SelectGroup>
              );
            }
            return (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            );
          })}
        </SelectContent>
      </Select>
      {/* // TODO should these both use `SupportingText` */}
      {error && (
        <section className="text-xs text-corso-red-600">{error}</section>
      )}
      {details && (
        <section className="text-xs text-corso-gray-500">{details}</section>
      )}
    </div>
  );
}
