import {
  faChevronDown,
  faLongArrowLeft,
} from '@fortawesome/pro-solid-svg-icons';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import { cva, VariantProps } from 'class-variance-authority';
import {
  ComponentProps,
  Fragment,
  Key,
  PropsWithChildren,
  ReactNode,
} from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { useDocumentTitle } from 'usehooks-ts';
import { cn } from '~/utils/shadcn';
import Icon, { SupportedIcon } from './Icon';
import Menu from './Menu';
import { Button } from './ui/primitives/Button';

// TODO replace locally defined actions with `Action` component and type

type BaseAction =
  | {
      icon: SupportedIcon;
      accessibilityLabel: string;
      content?: string;
      hidden?: boolean;
    }
  | {
      icon?: SupportedIcon;
      accessibilityLabel?: string;
      content: string;
      hidden?: boolean;
    };

type KeyId<T> = T & { id: Key };

// TODO implement `disabled` and `loading` states

type LinkAction = {
  to: LinkProps['to'];
  target?: LinkProps['target'];
  // links cannot be disabled via the `disabled`
};
// TODO consider renaming to `ButtonAction` with `onClick` instead
type CallbackAction = {
  onAction: () => void;
  disabled?: boolean;
  // loading?: boolean;
};

/**
 * From the `BaseAction`, an `Action` may have an `icon`, and if it does, it must have an `accessibilityLabel` or `content`.
 * With an `icon` and no `content`, the action will be a button with just the `icon`, but it it will have the `accessibilityLabel` as an `aria-label`.
 * With `content` and no `icon`, the action will be a button with just the `content`.
 * With both `icon` and `content`, the action will be a button with the `icon` and `content`, and optionally if the the `accessibilityLabel` is provided, it will be used as the `aria-label`.
 */
type Action = BaseAction & (LinkAction | CallbackAction);

type ActionGroup = KeyId<
  BaseAction & {
    actions: KeyId<Action>[];
    disabled?: boolean;
  }
>;

type BackAction = Required<Pick<BaseAction, 'accessibilityLabel'>> &
  (LinkAction | CallbackAction);

function PageActionContent({
  icon,
  accessibilityLabel,
  content,
  contents = false,
}: Partial<BaseAction> & {
  /** Override styling and simply display the contents. */
  contents?: boolean;
}) {
  return (
    <span
      className={cn(
        'inline-flex w-full items-center justify-center gap-2',
        contents && 'contents',
      )}
    >
      {icon && (
        <Icon
          icon={icon}
          accessibilityLabel={accessibilityLabel}
          className="size-4"
        />
      )}
      {content}
    </span>
  );
}

function PageAction({
  action,
  size = 'default',
  variant = 'default',
}: {
  action: Action;
  size?: ComponentProps<typeof Button>['size'];
  variant?: ComponentProps<typeof Button>['variant'];
}) {
  const isIconOnly =
    !!action.icon && (action.content === undefined || action.content === null);

  if ('onAction' in action) {
    return (
      <Button
        size={size}
        variant={variant}
        icon={isIconOnly}
        onClick={action.onAction}
        disabled={action.disabled}
        hidden={action.hidden}
      >
        <PageActionContent
          icon={action.icon}
          accessibilityLabel={action.accessibilityLabel}
          content={action.content}
        />
      </Button>
    );
  }

  return (
    <Button
      size={size}
      icon={isIconOnly}
      variant={variant}
      hidden={action.hidden}
      asChild
    >
      <Link to={action.to} target={action.target}>
        <PageActionContent
          icon={action.icon}
          accessibilityLabel={action.accessibilityLabel}
          content={action.content}
        />
      </Link>
    </Button>
  );
}

function PageActionGroup({
  action: { actions: subActions, ...action },
}: {
  action: ActionGroup;
}) {
  return (
    // ? iterate and possibly use underlying `DropdownMenu` instead
    <Menu
      align="end"
      buttonAs={
        <Button
          icon={!!action.icon}
          disabled={action.disabled}
          hidden={subActions.every((subAction) => subAction.hidden)}
        >
          <div className="flex items-center justify-between gap-1">
            <PageActionContent
              icon={action.icon}
              accessibilityLabel={action.accessibilityLabel}
              content={action.content}
            />
            <Icon icon={faChevronDown} className="size-3" />
          </div>
        </Button>
      }
    >
      {subActions.map((subAction) => (
        <Fragment key={subAction.id}>
          {'onAction' in subAction ?
            <Menu.ItemButton
              onClick={subAction.onAction}
              disabled={subAction.disabled}
              hidden={subAction.hidden}
            >
              <PageActionContent
                icon={subAction.icon}
                accessibilityLabel={subAction.accessibilityLabel}
                content={subAction.content}
                contents
              />
            </Menu.ItemButton>
          : <Menu.ItemLink
              to={subAction.to}
              target={subAction.target}
              hidden={subAction.hidden}
            >
              <PageActionContent
                icon={subAction.icon}
                accessibilityLabel={subAction.accessibilityLabel}
                content={subAction.content}
                contents
              />
            </Menu.ItemLink>
          }
        </Fragment>
      ))}
    </Menu>
  );
}

const pageVariants = cva(undefined, {
  variants: {
    size: {
      narrow: 'mx-auto max-w-screen-md',
      default: 'mx-auto max-w-screen-lg',
      fullWidth: undefined,
    },
  },
  defaultVariants: {
    size: 'default',
  },
});

/** Heavily inspired by the [`Page` component from Shopify's Polaris](https://polaris.shopify.com/components/layout-and-structure/page). */
export default function Page({
  backAction,

  title,
  titleMetadata,
  subtitle,

  primaryAction,
  secondaryActions,

  size,

  children,
}: PropsWithChildren<{
  backAction?: BackAction | true;

  title: string | ({ content: string } & LinkAction);
  /** Content shown immediately after, but inline, with the `title`. */
  titleMetadata?: ReactNode;
  /**
   * Content shown below the `title`.
   * Although this accepts a `ReactNode`, it's recommended to mostly use `string` values or a fragment with prose elements.
   */
  subtitle?: ReactNode;

  primaryAction?: Action;
  // TODO consider automatically wrapping secondary items that would overflow into a "more actions" group, but this would need to handle the case where there are too many groups
  secondaryActions?: (KeyId<Action> | ActionGroup)[];
}> &
  VariantProps<typeof pageVariants>) {
  const pageTitle = typeof title === 'string' ? title : title.content;
  useDocumentTitle(`Corso · ${pageTitle}`, {
    preserveTitleOnUnmount: false,
  });

  return (
    <section className={pageVariants({ size })}>
      <header className="mb-4 flex flex-col justify-between gap-2 md:flex-row md:items-center">
        <div className="flex items-start gap-2">
          {backAction &&
            (backAction === true ?
              <PageAction
                variant="ghost"
                action={{
                  icon: faLongArrowLeft,
                  to: '..', // default to navigating up one level
                  accessibilityLabel: 'Back',
                }}
              />
            : <PageAction
                variant="ghost"
                action={{
                  icon: faLongArrowLeft,
                  ...backAction,
                }}
              />)}
          <div>
            <div className="flex items-center justify-start gap-1.5">
              <h1 className="text-lg font-bold">
                {typeof title === 'string' ?
                  title
                : <Link
                    to={title.to}
                    target={title.target}
                    className="flex items-center gap-1 text-lg font-semibold hover:underline focus:underline"
                    rel="noreferrer"
                  >
                    {title.content}
                    {/* external link */}
                    {title.target === '_blank' && (
                      <ArrowTopRightOnSquareIcon className="h-4 w-4" />
                    )}
                  </Link>
                }
              </h1>
              {titleMetadata}
            </div>
            {subtitle && (
              <div className="text-sm text-gray-500">{subtitle}</div>
            )}
          </div>
        </div>
        <div className="flex flex-col flex-wrap gap-2 md:flex-row md:items-center md:justify-end">
          {/* // TODO many secondary actions moved into a more actions group/menu to avoid needing to wrap */}
          {secondaryActions?.map((action) =>
            'actions' in action ?
              <PageActionGroup key={action.id} action={action} />
            : <PageAction key={action.id} action={action} />,
          )}
          {primaryAction && (
            <PageAction variant="primary" action={primaryAction} />
          )}
          {/* // TODO page-specific pagination */}
        </div>
      </header>
      <main>{children}</main>
    </section>
  );
}
