import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import {
  AdjustmentsHorizontalIcon,
  BuildingStorefrontIcon,
  ClockIcon,
  CubeIcon,
  GlobeAltIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { Pegasus } from 'corso-types';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Alert from '~/components/Alert';
import Card from '~/components/Card';
import GridList from '~/components/GridList';
import InfoPopover from '~/components/InfoPopover';
import Separator from '~/components/Separator';
import { Action } from '~/components/ui/Action';
import { useConfigSettings } from '~/hooks/useConfigSettings';
import { useStoreId } from '~/hooks/useStoreId';
import { useStoreRules } from '~/hooks/useStoreRules';
import { StoreRuleDisplay } from '~/pages/settings/storeRules/StoreRuleDisplay';

// ----- rules -----

const eventTypes = [
  Pegasus.EventType.quoteOrderShipment,
  Pegasus.EventType.quoteOrderShipmentParcel,
  Pegasus.EventType.quoteOrderShipmentModify,
  Pegasus.EventType.quoteOrderShipmentDeliveryEstimate,
  Pegasus.EventType.themeAppExtensionLoad,
] as const satisfies Pegasus.EventType[];

type ShippingPlusEventTypes = (typeof eventTypes)[number];

const titleMap = {
  quoteOrderShipmentParcel: 'Parcel Size',
  quoteOrderShipmentModify: 'Rate Modification',
  themeAppExtensionLoad: 'Theme App Delivery Estimate',
  quoteOrderShipmentDeliveryEstimate: 'Shipping Delivery Estimate',
  quoteOrderShipment: 'Shipping Geo Zone',
} satisfies Record<ShippingPlusEventTypes, string>;

const descriptionMap = {
  quoteOrderShipment: `Provide shipping rates based on a customer's geographic location.`,
  quoteOrderShipmentParcel:
    'Customize parcel dimensions for precise shipping rate calculations.',
  quoteOrderShipmentModify:
    'Modify shipping rates dynamically by applying fees, discounts, or adjusting available options—add, remove, or replace rates as needed.',
  quoteOrderShipmentDeliveryEstimate:
    'Configure delivery estimates to provide customers with accurate shipping timelines at checkout.',
  themeAppExtensionLoad:
    'Configure delivery estimates for the Delivery Estimate Theme App Extension. This extension can be placed on the product page to show delivery estimates to customers.',
} satisfies Record<ShippingPlusEventTypes, string>;

const iconMap = {
  quoteOrderShipment: <GlobeAltIcon />,
  quoteOrderShipmentParcel: <CubeIcon />,
  quoteOrderShipmentModify: <AdjustmentsHorizontalIcon />,
  themeAppExtensionLoad: <BuildingStorefrontIcon />,
  quoteOrderShipmentDeliveryEstimate: <ClockIcon />,
} satisfies Record<ShippingPlusEventTypes, React.ReactNode>;

function ShippingPlusAddRule({
  onClose,
  showBack,
}: {
  onClose: () => void;
  showBack?: boolean;
}) {
  const storeId = useStoreId();
  const location = useLocation();

  const categories = eventTypes.map((eventType) => ({
    eventType,
    label: titleMap[eventType],
    description: descriptionMap[eventType],
    icon: iconMap[eventType],
  }));

  return (
    <>
      {showBack && (
        <div className="flex items-center gap-2">
          <Action icon={faArrowLeft} onClick={onClose} variant="ghost">
            Back
          </Action>
        </div>
      )}

      <GridList.Wrapper>
        <GridList columns={2} data={categories}>
          {({ eventType, label, description, icon }) => (
            <GridList.ItemLink
              key={eventType}
              to={{
                pathname: `/${storeId}/settings/automations/create`,
                search: new URLSearchParams({
                  eventType,
                  returnTo: location.pathname,
                }).toString(),
              }}
              label={label}
              description={description}
              icon={icon}
              variant="primary"
            />
          )}
        </GridList>
      </GridList.Wrapper>
    </>
  );
}

function ConfigurationAlert({
  isShippingPlusEnabled,
}: {
  isShippingPlusEnabled: boolean;
}) {
  return (
    <Alert
      variant={isShippingPlusEnabled ? 'warning' : 'info'}
      message={
        isShippingPlusEnabled ?
          'Shipping Plus is currently on, but no Shipping Geo Zone rules have been created or enabled.'
        : 'To get started with Shipping Plus, create or enable a Shipping Geo Zone rule.'
      }
    />
  );
}

export default function ShippingPlusRules() {
  const [createMode, setShowCreateMode] = useState(false);
  const location = useLocation();
  const { data } = useStoreRules();
  const { data: spSettings } = useConfigSettings(
    ({ shippingProtection }) => shippingProtection,
  );

  const isShippingPlusEnabled = !!spSettings?.isShippingLineProtectionOffered;

  const filteredStoreRules = data.filter((sr) =>
    eventTypes.includes(sr.rule.event.type as ShippingPlusEventTypes),
  );

  const isConfigured = filteredStoreRules.length > 0;
  const enabledGeoZoneRules = filteredStoreRules
    .filter((sr) => sr.hook === 'Order_Checkout_Begin')
    .filter((sr) => sr.isEnabled);

  const hasEnabledGeoZoneRules = enabledGeoZoneRules.length > 0;

  // empty state + create mode
  if (filteredStoreRules.length === 0 || createMode)
    return (
      <>
        {!isConfigured && (
          <ConfigurationAlert isShippingPlusEnabled={isShippingPlusEnabled} />
        )}

        <ShippingPlusAddRule
          onClose={() => setShowCreateMode(false)}
          showBack={filteredStoreRules?.length > 0}
        />
      </>
    );

  return (
    <div className="flex flex-col gap-4">
      {!hasEnabledGeoZoneRules && (
        <ConfigurationAlert isShippingPlusEnabled={isShippingPlusEnabled} />
      )}

      <Card>
        <div className="flex flex-row justify-between">
          <Card.Heading>Automations</Card.Heading>
          <Action
            icon={PlusIcon}
            variant="ghost"
            accessibilityLabel="Add New Shipping Plus Automation Rule"
            onClick={() => setShowCreateMode(true)}
          />
        </div>
        {eventTypes.map((eventType, index) => {
          const filteredRules = filteredStoreRules
            .filter((sr) => sr.rule.event.type === eventType)
            .sort(
              (a, b) =>
                new Date(b.createdOn).valueOf() -
                new Date(a.createdOn).valueOf(),
            );

          return filteredRules.length ?
              <>
                {/* Prevent separator before the first section */}
                {index > 0 && <Separator />}{' '}
                <div className="flex flex-row items-center gap-2">
                  <div className="inline-flex rounded-lg transition-all duration-300 *:h-6 *:w-6">
                    {iconMap[eventType]}
                  </div>
                  <p className="text-sm font-semibold text-corso-gray-900 opacity-75">
                    {titleMap[eventType]}
                  </p>
                  <InfoPopover title={titleMap[eventType]}>
                    <InfoPopover.Prose>
                      {descriptionMap[eventType]}
                    </InfoPopover.Prose>
                  </InfoPopover>
                </div>
                <ul className="flex flex-col gap-2">
                  {filteredRules.map((storeRule) => (
                    <li key={storeRule.id}>
                      <StoreRuleDisplay
                        storeRule={storeRule}
                        returnTo={location.pathname}
                      />
                    </li>
                  ))}
                </ul>
              </>
            : null;
        })}
      </Card>
    </div>
  );
}
