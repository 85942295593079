import { Pegasus } from 'corso-types';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import Alert from '~/components/Alert';
import Card from '~/components/Card';
import { NumberInput, TextInput } from '~/components/field';
import SimpleSelect, { SimpleSelectOption } from '~/components/ui/SimpleSelect';
import { StoreRuleFormData } from '~/types';

export const CUSTOM_PARCEL_NAME = 'Custom Parcel';
const CUSTOM_PARCEL = {
  name: CUSTOM_PARCEL_NAME,
  length: 10,
  width: 10,
  height: 10,
};
const predefinedParcels = [
  CUSTOM_PARCEL,
  {
    name: 'FedEx Small Box',
    length: 12.25,
    width: 10.9,
    height: 1.5,
  },
  {
    name: 'FedEx Medium Box',
    length: 13.25,
    width: 11.5,
    height: 2.38,
  },
  {
    name: 'FedEx Large Box',
    length: 17.88,
    width: 12.38,
    height: 3,
  },
  {
    name: 'UPS Small Box',
    length: 13,
    width: 11,
    height: 2,
  },
  {
    name: 'UPS Medium Box',
    length: 16,
    width: 11,
    height: 3,
  },
  {
    name: 'UPS Large Box',
    length: 18,
    width: 13,
    height: 3,
  },
  {
    name: 'UPS Extra Large Box',
    length: 18,
    width: 13,
    height: 6,
  },
  {
    name: 'UPS Tube',
    length: 38,
    width: 6,
    height: 6,
  },
] satisfies Pegasus.Parcel[];

export default function EventQuoteOrderShipmentParcel() {
  const { control, register, setValue } = useFormContext<StoreRuleFormData>();

  const parcelName = useWatch({ control, name: 'event.params.parcel.name' });

  const predefinedParcel = predefinedParcels.find(
    (p) => p.name === parcelName && p.name !== CUSTOM_PARCEL_NAME,
  );

  const handleSetParcel = (parcel: Pegasus.Parcel['name']) => {
    const foundParcel = predefinedParcels.find((p) => p.name === parcel);
    if (!foundParcel) {
      setValue(`event.params.parcel.name`, CUSTOM_PARCEL_NAME);
      return;
    }
    setValue(`event.params.parcel`, foundParcel, {
      shouldDirty: true,
    });
  };

  const parcelSelectOptions = predefinedParcels.map((parcel) => ({
    label: (
      <p className="flex flex-col items-start">
        <p>{parcel.name}</p>
        {parcel.name !== CUSTOM_PARCEL_NAME && (
          <p className="text-xs">
            {parcel.length} x {parcel.width} x {parcel.height} inches
          </p>
        )}
      </p>
    ),
    value: parcel.name,
  })) satisfies SimpleSelectOption[];

  const defaultParcel =
    predefinedParcels.find((p) => p.name === parcelName) ?? CUSTOM_PARCEL;

  return (
    <div className="flex flex-col gap-2">
      <Card>
        <SimpleSelect
          options={parcelSelectOptions}
          label="Type"
          value={defaultParcel.name}
          defaultValue={defaultParcel.name}
          onChange={(value) => handleSetParcel(value)}
        />

        {!predefinedParcel && (
          <>
            <TextInput
              id="parcel-name"
              required
              label="Name"
              details="The name of the parcel"
              {...register(`event.params.parcel.name`)}
            />

            <Controller
              control={control}
              name="event.params.parcel.length"
              render={({ field: f, fieldState }) => (
                <NumberInput
                  step={0.01}
                  required
                  label="Length"
                  details="The length of the parcel in inches."
                  value={f.value}
                  id="parcel-length"
                  error={fieldState.error?.message}
                  onChange={(e) => f.onChange(e.target.valueAsNumber)}
                />
              )}
            />

            <Controller
              control={control}
              name="event.params.parcel.width"
              render={({ field: f, fieldState }) => (
                <NumberInput
                  required
                  step={0.01}
                  label="Width"
                  details="The width of the parcel in inches."
                  value={f.value}
                  id="parcel-width"
                  error={fieldState.error?.message}
                  onChange={(e) => f.onChange(e.target.valueAsNumber)}
                />
              )}
            />

            <Controller
              control={control}
              name="event.params.parcel.height"
              render={({ field: f, fieldState }) => (
                <NumberInput
                  step={0.01}
                  label="Height"
                  details="The height of the parcel in inches."
                  value={f.value}
                  id="parcel-height"
                  error={fieldState.error?.message}
                  onChange={(e) => f.onChange(e.target.valueAsNumber)}
                />
              )}
            />

            <Alert
              variant="info"
              message="Using a custom parcel size, please ensure compatibility with your selected carrier rates."
            />
          </>
        )}
      </Card>
    </div>
  );
}
