import { useMutation } from '@tanstack/react-query';
import api from '~/api';
import { useStoreId } from './useStoreId';

export const useProductSync = () => {
  const storeId = useStoreId();

  return useMutation({
    mutationFn: () => api.store(storeId).product.sync(),
  });
};
