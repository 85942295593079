import { PlusIcon } from '@heroicons/react/20/solid';
import { CrewMerchantUi, Pegasus, isTruthy } from 'corso-types';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import Card from '~/components/Card';
import { NumberInput } from '~/components/field';
import { Action } from '~/components/ui/Action';
import { MultiSelect, MultiSelectOption } from '~/components/ui/MultiSelect';
import SimpleSelect from '~/components/ui/SimpleSelect';
import { useProtectionRates } from '~/hooks/useProtectionRates';
import { useStoreTimezone } from '~/hooks/useStoreTimezone';
import { ShippingRate } from '~/pages/settings/ShippingRate';
import { StoreRuleFormData } from '~/types';
import {
  estimatedDeliveryShippingCutOffOptions,
  usHolidayOptions,
} from '~/utils/enumNameMaps';

const estimatedDeliveryStrategyText = {
  CARRIER_PROVIDED: 'Carrier Provided',
  STATIC: 'Predefined Estimate',
} as const satisfies Record<Pegasus.EstimatedDeliveryStrategy, string>;

const estimatedDeliveryStrategyOptions = Object.values(
  Pegasus.EstimatedDeliveryStrategy,
).map((strategy) => ({
  value: strategy,
  label: estimatedDeliveryStrategyText[strategy],
}));

const getEstimateRateOptions = (
  protectionRates: CrewMerchantUi.ProtectionRate[],
  carrierOnly: boolean,
) => {
  const allProtectionRateOptions = protectionRates.map(
    (rate) =>
      ({
        value: `${rate.id}`,
        keywords: [rate.name, rate.description].filter(isTruthy),
        label: <ShippingRate rate={rate} />,
      }) satisfies MultiSelectOption,
  );

  const carrierProtectionRateOptions = protectionRates
    .filter((r) => r.carrierService)
    .map(
      (rate) =>
        ({
          value: `${rate.id}`,
          keywords: [rate.name, rate.description].filter(isTruthy),
          label: <ShippingRate rate={rate} />,
        }) satisfies MultiSelectOption,
    );

  return carrierOnly ? carrierProtectionRateOptions : allProtectionRateOptions;
};

export default function EventQuoteOrderShipmentDeliveryEstimate() {
  const { control, watch } = useFormContext<StoreRuleFormData>();
  const { data: protectionRates } = useProtectionRates();
  const timezone = useStoreTimezone(); // Ensuring store timezone is used

  const {
    fields: deliveryEstimateConfigs,

    append,
    remove,
  } = useFieldArray({
    control,
    name: 'event.params.deliveryEstimateConfigs',
  });

  return (
    <div className="flex flex-col gap-2">
      {deliveryEstimateConfigs.map((field, index) => {
        const strategy = watch(
          `event.params.deliveryEstimateConfigs.${index}.strategy`,
        );

        const protectionRateOptions = getEstimateRateOptions(
          protectionRates ?? [],
          strategy === 'CARRIER_PROVIDED',
        );

        return (
          <Card key={field.id}>
            <Card.Heading>Estimated Delivery Configuration</Card.Heading>

            <Controller
              control={control}
              name={`event.params.deliveryEstimateConfigs.${index}.strategy`}
              render={({ field: f, fieldState }) => (
                <SimpleSelect
                  label="Strategy"
                  options={estimatedDeliveryStrategyOptions}
                  onChange={f.onChange}
                  value={f.value}
                  error={fieldState.error?.message}
                />
              )}
            />

            <Controller
              control={control}
              name={`event.params.deliveryEstimateConfigs.${index}.estimatedDeliveryDisplayLocation`}
              render={({ field: f, fieldState }) => (
                <SimpleSelect
                  label="Display Location"
                  options={[
                    {
                      value: 'ADD_TO_DESCRIPTION',
                      label: 'Add To Description',
                    },
                    {
                      value: 'EXTENSION',
                      label: 'Delivery Estimate Extension',
                    },
                  ]}
                  onChange={f.onChange}
                  value={f.value}
                  error={fieldState.error?.message}
                />
              )}
            />

            {strategy === 'CARRIER_PROVIDED' && (
              <Controller
                control={control}
                name={`event.params.deliveryEstimateConfigs.${index}.carrierEstimatedFulfillmentDays`}
                render={({ field: f, fieldState }) => (
                  <NumberInput
                    id={`estimated-delivery-range-start-${index}`}
                    label="Fulfillment Days"
                    details="The number of additional days to add to the carrier provided estimated delivery date, to account for fulfillment."
                    addon={{ insideEnd: 'days' }}
                    value={f.value}
                    error={fieldState.error?.message}
                    onChange={(e) => f.onChange(e.target.valueAsNumber)}
                  />
                )}
              />
            )}

            {strategy === 'STATIC' && (
              <>
                <Controller
                  control={control}
                  name={`event.params.deliveryEstimateConfigs.${index}.estimatedDeliveryRangeStartDays`}
                  render={({ field: f, fieldState }) => (
                    <NumberInput
                      id={`estimated-delivery-range-start-${index}`}
                      label="Estimated Delivery Start"
                      details="The number of business days to the earliest possible delivery date."
                      addon={{ insideEnd: 'days' }}
                      value={f.value}
                      error={fieldState.error?.message}
                      onChange={(e) => f.onChange(e.target.valueAsNumber)}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name={`event.params.deliveryEstimateConfigs.${index}.estimatedDeliveryRangeEndDays`}
                  render={({ field: f, fieldState }) => (
                    <NumberInput
                      id={`estimated-delivery-range-end-${index}`}
                      label="Estimated Delivery End"
                      details="The number of business days to the latest possible delivery date."
                      value={f.value}
                      addon={{ insideEnd: 'days' }}
                      error={fieldState.error?.message}
                      onChange={(e) => f.onChange(e.target.valueAsNumber)}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name={`event.params.deliveryEstimateConfigs.${index}.estimatedDeliveryShippingCutOffTime`}
                  render={({ field: { onChange, value }, fieldState }) => (
                    <SimpleSelect
                      options={estimatedDeliveryShippingCutOffOptions.map(
                        (option) => ({
                          value: `${option.value}`,
                          label: option.label,
                        }),
                      )}
                      details="The time of day to cut off shipping for the estimated delivery date."
                      label="Shipping Cut Off Time"
                      onChange={onChange}
                      value={`${value}`}
                      error={fieldState.error?.message}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name={`event.params.deliveryEstimateConfigs.${index}.estimatedDeliveryHolidays`}
                  render={({ field: f, fieldState }) => (
                    <MultiSelect
                      estimateSize={114}
                      label="Holidays"
                      options={usHolidayOptions}
                      details="The US Holidays to use when calculating the estimated delivery date."
                      placeholder="Select Holidays"
                      value={f.value
                        ?.map((value) =>
                          usHolidayOptions.find(
                            (option) => option.value === `${value}`,
                          ),
                        )
                        .filter(isTruthy)}
                      onChange={(selected) =>
                        f.onChange(selected.map(({ value }) => value))
                      }
                      error={fieldState.error?.message}
                    />
                  )}
                />
              </>
            )}

            <Controller
              control={control}
              name={`event.params.deliveryEstimateConfigs.${index}.estimatedTargetStoreShipRateIds`}
              render={({ field: f, fieldState }) => (
                <MultiSelect
                  togglable
                  estimateSize={114}
                  label="Shipping Rates"
                  options={protectionRateOptions}
                  details="The rates to apply the delivery estimate to, if left empty all rates will be updated."
                  placeholder="Select Rates"
                  value={f.value
                    ?.map((value) =>
                      protectionRateOptions.find(
                        (option) => option.value === `${value}`,
                      ),
                    )
                    .filter(isTruthy)}
                  onChange={(selected) =>
                    f.onChange(selected.map(({ value }) => Number(value)))
                  }
                  error={fieldState.error?.message}
                />
              )}
            />

            <div className="flex">
              <Action onClick={() => remove(index)} variant="destructive">
                Remove
              </Action>
            </div>
          </Card>
        );
      })}

      <div>
        <Action
          icon={PlusIcon}
          onClick={() =>
            append({
              strategy: 'STATIC',
              estimatedDeliveryDisplayLocation: 'ADD_TO_DESCRIPTION',
              estimatedDeliveryRangeStartDays: 3,
              estimatedDeliveryRangeEndDays: 7,
              estimatedTargetStoreShipRateIds: [],
              estimatedDeliveryShippingCutOffTime: 0,
              estimatedDeliveryFulfillmentTz: timezone,
              estimatedDeliveryHolidays: Object.values(Pegasus.USHoliday),
            })
          }
        >
          Add Delivery Estimate
        </Action>
      </div>
    </div>
  );
}
