import { PencilIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  CorsoSupportedCurrency,
  CrewMerchantUi,
  currencyCodeNames,
} from 'corso-types';
import { FormEventHandler, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Alert from '~/components/Alert';
import { NumberInput, SwitchInput, TextInput } from '~/components/field';
import Modal from '~/components/Modal';
import { Action } from '~/components/ui/Action';
import SimpleSelect from '~/components/ui/SimpleSelect';

import {
  useAvailableCarrierOptions,
  useIsCarrierCalculatedRateCacheEnabled,
} from '~/hooks/useCarrierCalculatedRates';
import { useProtectionRateUpsert } from '~/hooks/useProtectionRates';
import { useMerchantContext } from '~/providers/MerchantProvider';
import {
  CarrierRetrievalMethod,
  CarrierService,
  ProtectionRate,
  ProtectionRateCreate,
  protectionRateCreateSchema,
  protectionRateUpdateSchema,
} from '~/types';
import { toCurrencyCode } from '~/utils/currencyCode';
import { getNullableNumberValue } from '~/utils/form';
import { formatter } from '~/utils/formatter';

const carrierServiceName = {
  // USPS
  USPS_GroundAdvantage: 'USPS Ground Advantage',
  USPS_Priority: 'USPS Priority Mail',
  USPS_Express: 'USPS Priority Mail Express',
  USPS_First: 'USPS First Class',
  USPS_MediaMail: 'USPS Media Mail',
  USPS_ExpressMailInternational: 'USPS Express Mail International',
  USPS_PriorityMailInternational: 'USPS Priority Mail International',
  USPS_FirstClassPackageInternationalService:
    'USPS First Class Package International Service',

  // FedEx Default Account (EasyPost Provided)
  FedExDefault_FEDEX_2_DAY: 'FedEx Default 2 Day',
  FedExDefault_FEDEX_GROUND: 'FedEx Default Ground',
  FedExDefault_FEDEX_INTERNATIONAL_CONNECT_PLUS:
    'FedEx Default International Connect Plus',
  FedExDefault_FEDEX_INTERNATIONAL_PRIORITY:
    'FedEx Default International Priority',
  FedExDefault_GROUND_HOME_DELIVERY: 'FedEx Default Ground Home Delivery',
  FedExDefault_INTERNATIONAL_ECONOMY: 'FedEx Default International Economy',

  // UPS DAP
  UPSDAP_Ground: 'UPS Ground',
  UPSDAP_UPSStandard: 'UPS Standard',
  UPSDAP_2ndDayAir: 'UPS 2nd Day Air',
  UPSDAP_NextDayAir: 'UPS Next Day Air',
  UPSDAP_Express: 'UPS Express',
  UPSDAP_3DaySelect: 'UPS 3 Day Select',

  // FedEx Owned Account (Merchant Provided)
  FedEx_FEDEX_2_DAY: 'FedEx 2 Day',
  FedEx_FEDEX_GROUND: 'FedEx Ground',
  FedEx_FEDEX_INTERNATIONAL_CONNECT_PLUS: 'FedEx International Connect Plus',
  FedEx_FEDEX_INTERNATIONAL_PRIORITY: 'FedEx International Priority',
  FedEx_GROUND_HOME_DELIVERY: 'FedEx Ground Home Delivery',
  FedEx_INTERNATIONAL_ECONOMY: 'FedEx International Economy',
  FedEx_STANDARD_OVERNIGHT: 'FedEx Standard Overnight',
  FedExDefault_STANDARD_OVERNIGHT: 'FedEx Standard Overnight',

  // UPS
  UPS_Ground: 'UPS Ground',
  UPS_UPSStandard: 'UPS Standard',
  UPS_2ndDayAir: 'UPS 2nd Day Air',
  UPS_NextDayAir: 'UPS Next Day Air',
  UPS_Express: 'UPS Express',
  UPS_3DaySelect: 'UPS 3 Day Select',

  // Canada Post
  CanadaPost_RegularParcel: 'Canada Post Regular Parcel',
  CanadaPost_ExpeditedParcel: 'Canada Post Expedited Parcel',
  CanadaPost_Xpresspost: 'Canada Post Xpresspost',
} as const satisfies Record<CarrierService, string>;

const carrierName = {
  [CrewMerchantUi.Carrier.USPS]: 'USPS',
  [CrewMerchantUi.Carrier.UPSDAP]: 'UPS',
  [CrewMerchantUi.Carrier.FedExDefault]: 'FedEx Default',
  [CrewMerchantUi.Carrier.FedEx]: 'FedEx',
  [CrewMerchantUi.Carrier.UPS]: 'UPS',
  [CrewMerchantUi.Carrier.CanadaPost]: 'Canada Post',
} as const satisfies Record<CrewMerchantUi.Carrier, string>;

const getDisplayPrice = (price: number, currencyCode: string) =>
  price === 0 ? 'FREE' : formatter.currency(price, currencyCode);

function CheckoutRateDisplay({
  rate,
}: {
  rate: Pick<
    ProtectionRate,
    | 'price'
    | 'name'
    | 'description'
    | 'currencyCode'
    | 'carrierService'
    | 'isPlusRate'
  >;
}) {
  const { price, name, description, currencyCode, carrierService, isPlusRate } =
    rate;

  const displayPrice =
    carrierService ? 'carrier price' : getDisplayPrice(price, currencyCode);

  return (
    <div className="rounded-xl border border-neutral-300 bg-corso-gray-100 p-6">
      <div className="flex justify-between gap-4">
        <div className="col-span-2 flex justify-center gap-4">
          <input
            type="radio"
            name="shippingMethod"
            className="form-radio h-5 w-5 text-corso-blue-600"
            checked
            readOnly
          />

          <div className="text-sm font-medium text-black">
            <p>{name}</p>

            <p className="text-xs text-corso-gray-500">{description}</p>
          </div>
        </div>

        <div className="flex flex-col text-right">
          <p className="text-wrap text-sm font-medium text-black">
            {displayPrice} {isPlusRate && '+ dynamic price'}
          </p>
        </div>
      </div>
    </div>
  );
}

const rateLabels = {
  Order_Total: 'Order Total',
  Item_Weight: 'Item Weight',
};

export default function ShippingRateForm({
  show,
  onClose,
  values,
}: {
  show: boolean;
  onClose: () => void;
  values?: ProtectionRate;
}) {
  const { mutate: upsert, isPending } = useProtectionRateUpsert();
  const [showEditCurrency, setShowEditCurrency] = useState(false);

  const {
    storeUser: {
      store: { currencyCode: storeCurrencyCode },
    },
  } = useMerchantContext();

  const {
    carriers,
    services: allServices,
    isCarrierCalculatedServiceEnabled: isMerchantCalculatedEnabled,
  } = useAvailableCarrierOptions();

  const isRateTableConfigured = useIsCarrierCalculatedRateCacheEnabled();

  const calculateRateLabel = {
    [CarrierRetrievalMethod.API]: 'API',
    [CarrierRetrievalMethod.RATE_TABLE]: 'Rate Table',
  } as const satisfies Record<CarrierRetrievalMethod, string>;

  const rateCalculateStrategyOptions = Object.values(
    CarrierRetrievalMethod,
  ).map((key) => ({
    value: key,
    label: calculateRateLabel[key],
  }));

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    register,
    watch,
    setValue,
    resetField,
  } = useForm<ProtectionRateCreate | ProtectionRate>({
    resolver: zodResolver(
      values?.id ? protectionRateUpdateSchema : protectionRateCreateSchema,
    ),
    values: values || {
      isPlusRate: true,
      shouldShowOriginalRate: false,
      currencyCode: toCurrencyCode(storeCurrencyCode),
      price: 0,
      name: '',
      type: 'Flat',
      minAmount: null,
      maxAmount: null,
      description: null,
      plusName: null,
      plusDescription: null,
      carrierService: undefined,
    },
  });

  const isPlusRate = watch('isPlusRate');
  const showOriginalRate = watch('shouldShowOriginalRate');
  const type = watch('type');
  const price = watch('price');
  const currencyCode = watch('currencyCode');
  const name = watch('name');
  const plusName = watch('plusName');
  const carrierService = watch('carrierService');
  const isConditionalEnabled = type !== 'Flat';

  const currencySymbol = formatter.currencySymbol(currencyCode);

  const currencyOptions = Object.values(CorsoSupportedCurrency).map((code) => ({
    value: code,
    label: currencyCodeNames[code],
  }));

  const closeAndReset = () => {
    onClose();
    reset();
  };

  const submitHandler: FormEventHandler = (event) => {
    handleSubmit((formValues) => {
      upsert(formValues);
      closeAndReset();
    })(event).catch(console.error);
  };

  /**
   * If a brand brings their own EasyPost config, there's a chance
   * they may have their own FedEx account which is different than
   * A Corso provided FedEx account
   */
  const calculateRateOptions = carriers.map(({ carrierCode, services }) => ({
    key: carrierCode,
    label: carrierName[carrierCode],
    options: services.map((service) => ({
      value: service,
      label: carrierServiceName[service],
    })),
  }));

  const isUnavailableCarrier =
    carrierService && !allServices.includes(carrierService);

  const handleConditionalPricing = () => {
    if (type === 'Flat') {
      setValue('type', 'Order_Total');
    } else {
      setValue('type', 'Flat');
      resetField('minAmount');
      resetField('maxAmount');
    }
  };

  const handleIsPlusRate = () => {
    if (isPlusRate) {
      setValue('plusName', null);
      setValue('plusDescription', null);
      setValue('shouldShowOriginalRate', false);
    }
  };

  const handleCarrierCalculate = () => {
    if (carrierService) {
      setValue('carrierService', undefined);
      resetField('name');
    } else {
      const defaultService = allServices[0] ?? 'USPS_GroundAdvantage';
      setValue('carrierService', defaultService);
      setValue('price', 0);
      setValue('carrierRetrievalMethod', CarrierRetrievalMethod.API);
      setValue('currencyCode', toCurrencyCode(storeCurrencyCode));
    }
  };

  const protectionRateFormId = `protection-rate-form`;

  const calcDetails =
    // eslint-disable-next-line no-nested-ternary
    isMerchantCalculatedEnabled ?
      'Using EasyPost Integration'
    : 'Using Corso Rates';

  return (
    <Modal
      show={show}
      onClose={closeAndReset}
      title={values?.id ? 'Edit Rate' : 'Add Rate'}
      actions={
        <>
          <Action onClick={closeAndReset}>Cancel</Action>
          <Action
            variant="primary"
            type="submit"
            form={protectionRateFormId}
            disabled={isPending}
            loading={isPending}
          >
            Save
          </Action>
        </>
      }
    >
      <form
        id={protectionRateFormId}
        className="flex flex-col gap-2"
        onSubmit={submitHandler}
      >
        {isUnavailableCarrier && (
          <Alert
            variant="warning"
            message="Carrier Service not supported by the connected EasyPost account."
          />
        )}
        <TextInput
          id="rate-name"
          label="Name"
          required
          {...register('name')}
          error={errors?.name?.message}
        />

        <div>
          <Action onClick={handleCarrierCalculate} variant="link">
            {carrierService ? 'Remove carrier service' : 'Add carrier service'}
          </Action>
        </div>

        <TextInput
          id="description"
          label="Description"
          {...register('description')}
          error={errors?.description?.message}
          details="The description of the rate."
        />

        {carrierService && (
          <>
            <Controller
              control={control}
              name="carrierService"
              render={({ field: { value, onChange } }) => (
                <SimpleSelect
                  details={calcDetails}
                  label="Carrier Service"
                  options={calculateRateOptions}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            {isRateTableConfigured && (
              <Controller
                control={control}
                name="carrierRetrievalMethod"
                render={({ field: { onChange, value }, fieldState }) => (
                  <SimpleSelect
                    options={rateCalculateStrategyOptions}
                    label="Carrier Retrieval Method"
                    details="The method to use when retrieving rates."
                    onChange={(v) => onChange(v)}
                    value={value}
                    error={fieldState.error?.message}
                  />
                )}
              />
            )}
          </>
        )}

        {!carrierService && (
          <>
            <div className="flex w-full items-center gap-1.5">
              <div className="flex-1">
                <Controller
                  control={control}
                  name="price"
                  render={({ fieldState, field: { value, onChange } }) => (
                    <NumberInput
                      required
                      disabled={!!carrierService}
                      id="flat-rate-price"
                      label="Price"
                      step="0.01"
                      addon={{
                        /**
                         *  not ideal as symbol placement depends on locale
                         * and in some locales the symbol is not used at all
                         * e.g. es-MX, es-AR the Euro symbol is not used
                         * but instead display prefixed with `EUR`
                         */
                        insideStart: currencySymbol,
                        insideEnd: currencyCode,
                      }}
                      details={
                        isPlusRate ?
                          'The price of the rate before Plus pricing is applied.'
                        : 'The price of the rate.'
                      }
                      error={fieldState.error?.message}
                      onChange={(event) =>
                        onChange(getNullableNumberValue(event))
                      }
                      value={value}
                    />
                  )}
                />
              </div>

              <Action
                onClick={() => setShowEditCurrency(!showEditCurrency)}
                icon={PencilIcon}
                accessibilityLabel="Edit Currency"
                variant="ghost"
              />
            </div>

            {showEditCurrency && (
              <Controller
                control={control}
                name="currencyCode"
                render={({ field: { value, onChange } }) => (
                  <SimpleSelect
                    label="Currency"
                    options={currencyOptions}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            )}
          </>
        )}

        <div>
          <Action onClick={handleConditionalPricing} variant="link">
            {isConditionalEnabled ?
              'Remove conditional pricing'
            : 'Add conditional pricing'}
          </Action>
        </div>

        {isConditionalEnabled && (
          <>
            <Controller
              control={control}
              name="type"
              render={({ field: { value, onChange } }) => (
                <SimpleSelect
                  label="Type"
                  labelVisuallyHidden
                  options={Object.entries(rateLabels).map(([key, label]) => ({
                    value: key,
                    label,
                  }))}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            <div className="grid grid-cols-2 gap-4">
              <Controller
                control={control}
                name="minAmount"
                render={({ fieldState, field: { value, onChange } }) => (
                  <NumberInput
                    id="min-specifier"
                    placeholder={type === 'Item_Weight' ? '0' : '0.00'}
                    label={
                      type === 'Item_Weight' ? 'Minimum weight' : (
                        'Minimum price'
                      )
                    }
                    addon={
                      type === 'Item_Weight' ?
                        { insideEnd: 'oz' }
                      : {
                          insideStart: currencySymbol,
                        }
                    }
                    step={type === 'Item_Weight' ? '1' : '0.01'}
                    error={fieldState.error?.message}
                    onChange={(event) =>
                      onChange(getNullableNumberValue(event))
                    }
                    value={value}
                  />
                )}
              />

              <Controller
                control={control}
                name="maxAmount"
                render={({ fieldState, field: { value, onChange } }) => (
                  <NumberInput
                    id="max-specifier"
                    label={
                      type === 'Item_Weight' ? 'Maximum weight' : (
                        'Maximum price'
                      )
                    }
                    addon={
                      type === 'Item_Weight' ?
                        { insideEnd: 'oz' }
                      : {
                          insideStart: currencySymbol,
                        }
                    }
                    step={type === 'Item_Weight' ? '1' : '0.01'}
                    placeholder="No limit"
                    error={fieldState.error?.message}
                    onChange={(event) =>
                      onChange(getNullableNumberValue(event))
                    }
                    value={value}
                  />
                )}
              />
            </div>
          </>
        )}

        {/* rates imported from Shopify are always Plus */}

        <Controller
          control={control}
          name="isPlusRate"
          render={({ field: { onChange, value }, fieldState }) => (
            <SwitchInput
              id="is-remove-platform-rate-enabled"
              label="Enable Plus Rate"
              checked={!!value}
              onChange={() => {
                onChange(!value);
                handleIsPlusRate();
              }}
              error={fieldState.error?.message}
            />
          )}
        />

        {isPlusRate && (
          <div className="flex flex-col gap-3 rounded-md border border-gray-200 p-4">
            {/* If the rate is managed on Shopify, then allow a separate protected name + protected description  */}

            {name === plusName && showOriginalRate && (
              <Alert
                variant="warning"
                message="In order to show both rates, the Plus rate name must be different from the original rate name."
              />
            )}

            <Controller
              control={control}
              name="shouldShowOriginalRate"
              render={({ field: { onChange, value }, fieldState }) => (
                <SwitchInput
                  id="is-remove-platform-rate-enabled"
                  label="Show Original Rate"
                  details={
                    showOriginalRate ?
                      'Both the original rate and the Plus rate will be shown.'
                    : 'Only the Plus rate will be shown.'
                  }
                  checked={!!value}
                  onChange={onChange}
                  error={fieldState.error?.message}
                />
              )}
            />

            <TextInput
              id="plus-name"
              label="Plus Name"
              details="The name of the Plus rate."
              {...register('plusName')}
              error={errors?.plusName?.message}
            />

            <TextInput
              id="plus-description"
              label="Plus Description"
              details="The description of the Plus rate."
              {...register('plusDescription')}
              error={errors?.plusDescription?.message}
            />
          </div>
        )}
        {(name || plusName) && (
          <>
            <p className="text-sm font-medium"> Checkout Preview </p>
            <CheckoutRateDisplay
              rate={{
                isPlusRate,
                name: isPlusRate && plusName ? plusName : name,
                description:
                  isPlusRate && watch('plusDescription') ?
                    watch('plusDescription')
                  : watch('description'),
                price,
                currencyCode,
                carrierService,
              }}
            />
          </>
        )}
      </form>
    </Modal>
  );
}
