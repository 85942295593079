import {
  CorsoDefaultCarrier,
  carrierServiceUtils,
  isTruthy,
} from 'corso-types';
import { useConfigSettings } from './useConfigSettings';
import { useIntegrationSettingsData } from './useIntegrationSettings';

export function useIsCarrierCalculatedRateCacheEnabled() {
  const { data } = useConfigSettings(
    ({ shippingProtection }) => shippingProtection,
  );

  return !!data?.widgetConfig?.shippingPlus?.isRateTableCacheEnabled;
}

export function useAvailableCarrierOptions() {
  const { data: { easyPostConfig } = {} } = useIntegrationSettingsData();

  const isCarrierCalculatedServiceEnabled =
    !!easyPostConfig?.isCarrierCalculatedServiceEnabled;

  if (isCarrierCalculatedServiceEnabled) {
    const carriers = easyPostConfig?.enabledCarriers
      .map((c) => c.ccsDetail)
      .filter(isTruthy);

    return {
      carriers,
      services: carriers.flatMap((c) => c.services),
      isCarrierCalculatedServiceEnabled,
    };
  }

  const corsoDefaultCarriers = Object.values(CorsoDefaultCarrier).map((c) => ({
    carrierCode: c,
    services: carrierServiceUtils.extractServicesFromCarriers(c),
  }));

  return {
    isCarrierCalculatedServiceEnabled: false,
    carriers: corsoDefaultCarriers,
    services: corsoDefaultCarriers.flatMap((c) => c.services),
  };
}
