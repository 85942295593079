import {
  faCanadianMapleLeaf,
  faFedex,
  faUps,
  faUsps,
} from '@fortawesome/free-brands-svg-icons';
import { faHexagonPlus, faTruck } from '@fortawesome/pro-light-svg-icons';
import {
  faEye,
  faWarning,
  IconDefinition,
} from '@fortawesome/pro-solid-svg-icons';
import { carrierServiceUtils } from 'corso-types';
import { ReactNode } from 'react';
import Icon from '~/components/Icon';
import { Badge } from '~/components/ui/primitives/Badge';

import {
  useAvailableCarrierOptions,
  useIsCarrierCalculatedRateCacheEnabled,
} from '~/hooks/useCarrierCalculatedRates';
import { Carrier, ProtectionRate } from '~/types';
import { formatter } from '~/utils/formatter';
import { cn } from '~/utils/shadcn';

const getDisplayPrice = (price: number, currencyCode: string) =>
  price === 0 ? 'FREE' : formatter.currency(price, currencyCode);

const conditionalPriceString = (rate: ProtectionRate) => {
  if (rate.type === 'Flat') return null;

  const { maxAmount, minAmount, type, currencyCode } = rate;

  if (minAmount && maxAmount)
    return type === 'Order_Total' ?
        `Orders ${formatter.currency(minAmount, currencyCode)} - ${formatter.currency(maxAmount, currencyCode)}`
      : `Orders ${minAmount}oz - ${maxAmount}oz`;

  if (minAmount)
    return type === 'Order_Total' ?
        `Orders ${formatter.currency(minAmount, currencyCode)} and up`
      : `Orders ${minAmount}oz and up`;

  if (maxAmount)
    return type === 'Order_Total' ?
        `Orders ${formatter.currency(0, currencyCode)}-${formatter.currency(maxAmount, currencyCode)}`
      : `Orders 0-${maxAmount}oz`;

  return null;
};

// TODO: Refactor this to use the carrier service instead
const carrierIconMap = {
  CanadaPost: faCanadianMapleLeaf,
  FedEx: faFedex,
  UPS: faUps,
  USPS: faUsps,
  FedExDefault: faFedex,
  UPSDAP: faUps,
} satisfies Record<Carrier, IconDefinition>;

const carrierIconSizeMap = {
  CanadaPost: 'size-5',
  FedEx: 'size-8',
  UPS: 'size-6',
  USPS: 'size-6',
  FedExDefault: 'size-8',
  UPSDAP: 'size-6',
} satisfies Record<Carrier, string>;

export function ShippingRate({
  rate,
  children,
}: {
  rate: ProtectionRate;
  children?: ReactNode;
}) {
  const {
    price,
    name,
    description,
    plusDescription,
    plusName,
    currencyCode,
    shouldShowOriginalRate,
    carrierService,
    isPlusRate,
    carrierRetrievalMethod,
  } = rate;

  const displayPrice =
    // eslint-disable-next-line no-nested-ternary
    carrierService ?
      carrierRetrievalMethod === 'RATE_TABLE' ?
        'rate table price'
      : 'carrier price'
    : getDisplayPrice(price, currencyCode);

  const displayName = isPlusRate ? (plusName ?? name) : name;

  const displayDescription =
    isPlusRate ? (plusDescription ?? description) : description;

  const conditionalDisplay = conditionalPriceString(rate);

  const isRateTableConfigured = useIsCarrierCalculatedRateCacheEnabled();

  const { services } = useAvailableCarrierOptions();

  const isUnavailableCarrier =
    carrierService && !services.includes(carrierService);

  const isMisconfiguredRateTable =
    carrierService &&
    carrierRetrievalMethod === 'RATE_TABLE' &&
    !isRateTableConfigured;

  const showWarning = isUnavailableCarrier || isMisconfiguredRateTable;

  const carrier = carrierServiceUtils.extractCarrier(carrierService);

  const carrierIcon = carrier ? carrierIconMap[carrier] : faTruck;
  const carrierIconSize = carrier ? carrierIconSizeMap[carrier] : 'size-5';

  return (
    <div className="flex items-center justify-center gap-1.5">
      {/* grid */}
      <div
        className={cn(
          "grid flex-grow grid-cols-[1fr_auto] gap-x-2 gap-y-1 [grid-template-areas:'name_price']",
          (displayDescription || conditionalDisplay) &&
            `[grid-template-areas:'name_price'_'description_conditional']`,
        )}
      >
        <div className="flex items-center gap-2 [grid-area:name]">
          <p className="text-sm font-medium text-corso-gray-800">
            {displayName}
          </p>

          {showWarning && (
            <Icon icon={faWarning} className="text-corso-orange-700" />
          )}

          {isPlusRate && (
            <Icon icon={faHexagonPlus} className="flex-shrink-0 text-black" />
          )}
          {shouldShowOriginalRate && (
            <Icon icon={faEye} className="flex-shrink-0 text-black" />
          )}

          {carrierService && (
            <Icon
              className={cn('"flex-shrink-0 " text-black', carrierIconSize)}
              icon={carrierIcon}
            />
          )}
        </div>

        <div className="justify-self-end [grid-area:price]">
          <Badge variant={displayPrice === 'FREE' ? 'success' : 'info'}>
            {displayPrice} {isPlusRate && '+ dynamic price'}
          </Badge>
        </div>

        {displayDescription && (
          <div className="justify-self-start [grid-area:description]">
            <p className="text-xs text-corso-gray-600">{displayDescription}</p>
          </div>
        )}

        {conditionalDisplay && (
          <div className="[grid-area:conditional]">
            <p className="text-right text-xs text-corso-gray-600">
              {conditionalDisplay}
            </p>
          </div>
        )}
      </div>

      {children}
    </div>
  );
}
