import {
  faAddressBook,
  faBadgeCheck,
  faBoxCheck,
  faExchange,
  faMagnifyingGlass,
  faShoppingCart,
  IconDefinition,
} from '@fortawesome/pro-light-svg-icons';
import { faFlowerTulip } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import { CrewMerchantApi, isTruthy } from 'corso-types';
import { useMemo, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useDebounceValue, useEventListener } from 'usehooks-ts';
import api from '~/api';
import { useEnabledClaimType } from '~/hooks/useEnabledClaimType';
import { useStoreId } from '~/hooks/useStoreId';
import Icon from './Icon';
import { Loading } from './Loading';
import RelativeDateTime from './RelativeDateTime';
import { Badge } from './ui/primitives/Badge';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandLoading,
} from './ui/primitives/Command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from './ui/primitives/Popover';

type SearchKind = CrewMerchantApi.RequestBody<
  '/:storeId/search',
  'post'
>['kind'];

function useSearchByKind(searchTerm: string, kind: SearchKind) {
  const storeId = useStoreId();
  const {
    isReturnsEnabled,
    isWarrantyEnabled,
    isShippingProtectionEnabled,
    isRegistrationEnabled,
  } = useEnabledClaimType();

  const kindEnabled = {
    Return: isReturnsEnabled,
    Warranty: isWarrantyEnabled,
    Shipping: isShippingProtectionEnabled,
    Registration: isRegistrationEnabled,
    Order: true,
  } as const satisfies Record<SearchKind, boolean>;

  return useQuery({
    queryKey: ['globalSearch', kind, storeId, searchTerm],
    enabled: !!searchTerm.length && kindEnabled[kind],
    queryFn: () =>
      api.store(storeId).search({
        kind,
        cursor: null,
        orderBy: 'desc',
        take: 5,
        filters: { searchTerm },
      }),
  });
}

function useSearchData(searchTerm: string) {
  const returnsQuery = useSearchByKind(searchTerm, 'Return');
  const warrantiesQuery = useSearchByKind(searchTerm, 'Warranty');
  const shippingQuery = useSearchByKind(searchTerm, 'Shipping');
  const registrationsQuery = useSearchByKind(searchTerm, 'Registration');
  const ordersQuery = useSearchByKind(searchTerm, 'Order');

  const isLoading = [
    returnsQuery,
    warrantiesQuery,
    shippingQuery,
    registrationsQuery,
    ordersQuery,
  ].some((query) => query.isLoading);

  const isRefetching = [
    returnsQuery,
    warrantiesQuery,
    shippingQuery,
    registrationsQuery,
    ordersQuery,
  ].some((query) => query.isRefetching);

  return useMemo(
    () => ({
      isLoading,
      isRefetching,
      queries: {
        returnsQuery,
        warrantiesQuery,
        shippingQuery,
        registrationsQuery,
        ordersQuery,
      },
      // data effectively a select on the original request, since the response does not know kind requested
      returns:
        returnsQuery.data?.kind === 'Return' ? returnsQuery.data.data : [],
      warranties:
        warrantiesQuery.data?.kind === 'Warranty' ?
          warrantiesQuery.data.data
        : [],
      shipping:
        shippingQuery.data?.kind === 'Shipping' ? shippingQuery.data.data : [],
      registrations:
        registrationsQuery.data?.kind === 'Registration' ?
          registrationsQuery.data.data
        : [],
      orders: ordersQuery.data?.kind === 'Order' ? ordersQuery.data.data : [],
    }),
    [
      isLoading,
      isRefetching,
      returnsQuery,
      warrantiesQuery,
      shippingQuery,
      registrationsQuery,
      ordersQuery,
    ],
  );
}

function QuickSearchListItem({
  to,
  externalId,
  customerName,
  createdOn,
  icon,
}: {
  to: string;
  externalId: string;
  customerName: string | null;
  createdOn: string;
  icon: IconDefinition;
}) {
  return (
    <NavLink to={to} className="flex w-full items-center gap-4 px-2">
      <FontAwesomeIcon icon={icon} className="text-neutral-700" />
      <div>
        <p className="text-xs">{externalId}</p>
        {customerName && <p className="text-xs">{customerName}</p>}
        <p className="text-xs">
          Created <RelativeDateTime dateTime={createdOn} />
        </p>
      </div>
    </NavLink>
  );
}

export default function QuickSearch() {
  const storeId = useStoreId();
  const trigger = useRef<HTMLButtonElement>(null);
  const [debouncedSearch, setDebouncedSearch] = useDebounceValue('', 1000);
  const [popoverOpen, setPopoverOpen] = useState(false);

  // one of the known use cases for the deprecated `platform` property
  const isApplePlatform = useMemo(
    () => /Mac|iPod|iPhone|iPad/.test(navigator.platform),
    [],
  );

  useEventListener('keydown', (event) => {
    if (event.key !== 'k') return;
    if (!(event.metaKey || event.ctrlKey)) return;
    event.preventDefault();
    setPopoverOpen(true);
  });

  const {
    isReturnsEnabled,
    isWarrantyEnabled,
    isShippingProtectionEnabled,
    isRegistrationEnabled,
  } = useEnabledClaimType();

  const searchBy = [
    isReturnsEnabled && 'Returns',
    isWarrantyEnabled && 'Warranties',
    isShippingProtectionEnabled && 'Shipping Claims',
    isRegistrationEnabled && 'Registrations',
  ].filter(isTruthy);

  const searchByString = `Find ${searchBy.join(', ')} and Orders`;
  const {
    isLoading,
    isRefetching,
    returns,
    warranties,
    shipping,
    registrations,
    orders,
  } = useSearchData(debouncedSearch);

  const hasSomeResults =
    !!returns.length ||
    !!warranties.length ||
    !!orders.length ||
    !!registrations.length ||
    !!shipping.length;

  const handleItemSelect = () => setPopoverOpen(false);

  const isAwaitingResults = isLoading || isRefetching;

  return (
    // omitting clearing debouncedSearch from onOpenChange allows the previous results to be displayed
    <Popover
      modal
      open={popoverOpen}
      onOpenChange={(open) => setPopoverOpen(open)}
    >
      <PopoverTrigger asChild>
        <button
          type="button"
          className="group w-full max-w-xl rounded-xl bg-gradient-to-br from-neutral-600 via-neutral-700 to-neutral-600 p-px text-left shadow-md transition-all duration-100 hover:bg-gradient-to-bl hover:from-neutral-700 hover:via-neutral-800 hover:to-neutral-700"
          ref={trigger}
        >
          <div className="flex w-full items-center rounded-xl bg-neutral-800 px-3 py-1.5 text-sm text-white/50 transition-all duration-100 group-hover:bg-neutral-950">
            <div className="flex w-full items-center justify-between">
              <div>
                <FontAwesomeIcon icon={faFlowerTulip} className="mr-2" />
                Quick Search
              </div>
              <kbd className="flex items-center justify-center rounded-md border border-neutral-600 px-1 py-0.5 font-sans text-xs">
                {isApplePlatform ? '⌘' : 'Ctrl'}&nbsp;K
              </kbd>
            </div>
          </div>
        </button>
      </PopoverTrigger>
      <PopoverContent
        className="p-0"
        // overlap the content over the trigger
        sideOffset={-1 * (trigger.current?.offsetHeight ?? 0)}
      >
        <Command>
          <CommandInput
            placeholder="Quick Search"
            className="w-full"
            onValueChange={setDebouncedSearch}
          />
          {hasSomeResults && (
            <div className="m-4 flex justify-start space-x-4">
              {returns.length > 0 && <Badge> Returns</Badge>}
              {warranties.length > 0 && <Badge> Warranties</Badge>}
              {orders.length > 0 && <Badge> Orders</Badge>}
              {registrations.length > 0 && <Badge> Registrations</Badge>}
              {shipping.length > 0 && <Badge> Shipping</Badge>}
            </div>
          )}

          <CommandList>
            {!isAwaitingResults && (
              <CommandEmpty>
                <div>
                  <Icon
                    icon={faMagnifyingGlass}
                    className="h-10 w-10 text-neutral-500"
                  />
                  <p className="m-2 mt-4 text-sm text-neutral-500">
                    {searchByString}
                  </p>
                </div>
              </CommandEmpty>
            )}

            {isAwaitingResults && (
              <CommandLoading>
                <Loading />
              </CommandLoading>
            )}

            {!!returns.length && (
              <CommandGroup heading={<p className="text-xs">Returns</p>}>
                {returns.map((item) => {
                  const value = Object.values(item).join(' ');
                  return (
                    <CommandItem
                      key={`returns-${item.id}`}
                      value={value}
                      onSelect={handleItemSelect}
                    >
                      <QuickSearchListItem
                        createdOn={item.createdOn}
                        customerName={item.customerName}
                        externalId={`#${item.externalId}`}
                        to={`/${storeId}/claims/return/${item.id}`}
                        icon={faExchange}
                      />
                    </CommandItem>
                  );
                })}
              </CommandGroup>
            )}

            {!!warranties.length && (
              <CommandGroup heading={<p className="text-xs">Warranties</p>}>
                {warranties.map((item) => {
                  const value = Object.values(item).join(' ');
                  return (
                    <CommandItem
                      key={`warranties-${item.id}`}
                      value={value}
                      onSelect={handleItemSelect}
                    >
                      <QuickSearchListItem
                        to={`/${storeId}/claims/warranty/${item.id}`}
                        createdOn={item.createdOn}
                        customerName={item.customerName}
                        externalId={`#${item.externalId}`}
                        icon={faBadgeCheck}
                      />
                    </CommandItem>
                  );
                })}
              </CommandGroup>
            )}

            {!!orders.length && (
              <CommandGroup heading={<p className="text-xs">Orders</p>}>
                {orders.map((item) => {
                  const value = Object.values(item).join(' ');
                  return (
                    <CommandItem
                      key={`orders-${item.id}`}
                      value={value}
                      onSelect={handleItemSelect}
                    >
                      <QuickSearchListItem
                        to={`/${storeId}/orders/lookup/${item.idFromPlatform}`}
                        customerName={item.customerName ?? item.customerEmail}
                        createdOn={item.createdOn}
                        externalId={item.orderNo}
                        icon={faShoppingCart}
                      />
                    </CommandItem>
                  );
                })}
              </CommandGroup>
            )}

            {!!shipping.length && (
              <CommandGroup heading={<p className="text-xs">Shipping</p>}>
                {shipping.map((item) => {
                  const value = Object.values(item).join(' ');
                  return (
                    <CommandItem
                      key={`shipping-${item.id}`}
                      value={value}
                      onSelect={handleItemSelect}
                    >
                      <QuickSearchListItem
                        to={`/${storeId}/claims/shipping/${item.id}`}
                        customerName={item.customerName ?? item.customerEmail}
                        createdOn={item.createdOn}
                        externalId={`#${item.id}`}
                        icon={faBoxCheck}
                      />
                    </CommandItem>
                  );
                })}
              </CommandGroup>
            )}

            {!!registrations.length && (
              <CommandGroup heading={<p className="text-xs">Registrations</p>}>
                {registrations.map((item) => {
                  const value = Object.values(item).join(' ');
                  return (
                    <CommandItem
                      key={`registrations-${item.id}`}
                      value={value}
                      onSelect={handleItemSelect}
                    >
                      <QuickSearchListItem
                        createdOn={item.createdOn}
                        customerName={item.customerName ?? item.customerEmail}
                        externalId={`#${item.externalId}`}
                        icon={faAddressBook}
                        to={`/${storeId}/registrations/${item.id}`}
                      />
                    </CommandItem>
                  );
                })}
              </CommandGroup>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
